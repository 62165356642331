<!-- build:scripto -->
<template>
  <div class="container welcome-screen2"
      :class="{
        'has-description': !homescreen
      }"
    >
      <Background2 v-if="homescreen"/>
      <div v-if="!homescreen">
        <Description :userSettings="userSettings" @homescreen="homescreen=true"/>
      </div>
      <div v-if="homescreen" class="col-12 form">
          <div class="content-top">
            <a href="" class="logo-wrapper">
                <img :src="userSettings['logo']" :alt="userSettings['permainan']" @click.prevent="logoClick"/>
            </a>
            <div id="game-logo-id" class="game-logo">
                <img :src="userSettings['game-logo']" :alt="userSettings['game-short']" />
                <a href="" class="what-is-sticky" v-on:click.prevent="btnClick">
                  <img class="what-is-image" src="../assets/images/what-is-this-icon.png">
                  <p class="what-is-text" v-html="userSettings['game-whatis']"></p>
                </a>
            </div>
            <div class="game-intro">
                <h2 v-html="userSettings['game-short']"></h2>
            </div>
          </div>
          <div class="content-bottom">
            <div class="player-info">
              <form v-if="gameMode == 'fast_alt'" @submit.prevent="playerAnswer('')" class="player-submission">
                <input type="button" :value="userSettings['play']" class="button inline yellow"  @click.prevent="playerAnswer('')" />
                <div class="term-inside">
                  <a :href="userSettings['privacy-url']" target="_blank" class="link">{{ userSettings['privacy-label'] }}</a> |
                  <a  class="link" :href="userSettings['tnc-url']" target="_blank" >{{ userSettings['tnc-label'] }}</a>
                </div>
              </form>
              <form v-else @submit.prevent="playerAnswer(playerName)" class="player-submission">
                <input type="text" maxlength="12" :placeholder="userSettings['your-name']" v-model="playerName" class="input-text" @input="checkPlayerNameLength"/>
                <input type="button" :disabled="!isPlayerNameValid" :value="userSettings['play']" class="button inline yellow"  @click.prevent="playerAnswer(playerName)" />
                <div class="term-inside">
                  <a :href="userSettings['privacy-url']" target="_blank" class="link">{{ userSettings['privacy-label'] }}</a> |
                  <a  class="link" :href="userSettings['tnc-url']" target="_blank" >{{ userSettings['tnc-label'] }}</a>
                </div>
              </form>
            </div>
            <div class="term">
              <a href="" class="logo-wrapper">
                  <img :src="userSettings['logo']" :alt="userSettings['permainan']" @click.prevent="logoClick"/>
              </a>
              <a :href="userSettings['privacy-url']" target="_blank" class="link">{{ userSettings['privacy-label'] }}</a>
              <p>|</p>
              <a  class="link" :href="userSettings['tnc-url']" target="_blank" >{{ userSettings['tnc-label'] }}</a>
            </div>
          </div>
      </div>
    </div>
</template>
<script>
import Background2 from './Background2.vue'
import Description from './Description.vue'
import buttonSound from '@/assets/sounds/button.wav'

  export default {
  components: { Background2, Description },
    name: 'WelcomeScreen2',
    props: {
      userSettings: {
        default: {},
        required: false
      },
      gameMode: {
        default: '',
        required: false
      }
    },
    created () {
    },
    mounted () {
    },
    data () {
      return {
        playerName: '',
        langs: ['en', 'id'],
        homescreen: true,
        soundHandler: {
          button: new Audio(buttonSound)
        },
        isPlayerNameValid: false,
        debounceTimeout: null,
        debounceDelay: 500
      }
    },
    methods: {
        playerAnswer (name) {
          this.soundHandler.button.play()
          this.$store.commit('gamePlay/setName', name)
          this.$emit('playerAnswer', name)
        },
        btnClick: function () {
          this.soundHandler.button.play()
          this.homescreen = false
        },
        logoClick: function () {
          this.soundHandler.button.play()
          window.open('https://literata.id/')
        },
        checkPlayerNameLength () {
          this.isPlayerNameValid = this.playerName.trim().length > 0
        },
        debouncedCheckPlayerNameLength () {
          // clear any previously set timeout to avoid multiple timeouts running at the same time
          clearTimeout(this.debounceTimeout)

          // set a new timeout to delay the check for the player name
          this.debounceTimeout = setTimeout(() => {
            this.checkPlayerNameLength()
          }, this.debounceDelay)
        }
    }
  }
</script>
