<template>
    <div>
      <!-- hack to make component reative? -->
      <div class="d-none">{{ roomInfo }}</div>
      <template v-for="chat in chats">
        <div v-if="chat.isNewMessage" v-bind:key="'new-'+chat.historyID" class="text-new">
          <div class="line"></div>
          <div class="text">{{ userSettings.new }}</div>
        </div>
        <div v-if="chat.type === 'answer'" v-bind:key="chat.historyID" class="chat-bubble answer bot" :class="{
          'play-animation' : chat.playAnimation
        }">
            <div class="content" :class="chat.className" >
              <div v-if="chat.className !== 'neutral'" class="player-thumb-answer">
                <Stickers :stickerName="chat.className"></Stickers>
              </div>
              <template v-if="getField(chat, 'long_message', false)">
                <p class="message" v-html="formatMessage(chat.long_message)"></p>
              </template>
              <template v-else>
                <p class="message" v-html="formatMessage(chat.message)"></p>
              </template>
            </div>
        </div>
        <div v-else-if="chat.chatType === 'article'" v-bind:key="chat.historyID" class="chat-bubble article bot"
          :class="[
            chat.user.toLowerCase(),
            chat.lastChatUser.toLowerCase() == chat.user.toLowerCase() ? 'same-actor' : '',
            chat.playAnimation ? 'play-animation' : ''
          ]"
        >
          <div class="profile-picture">
            <img :src="icons[chat.user.toLowerCase()]" width="32" />
          </div>
            <div class="content" :class="chat.className"  @click.prevent="$emit('viewMedia', chat.image)" >
              <div class="actor">{{ userSettings[chat.user] }}</div>
              <div class="thumb">
                <ImageLoad @onLoad="$emit('imageReady')" >
                  <b-img slot="image" fluid :src="chat.image.thumb" :alt="getField(chat, 'image.thumb_caption', '')" />
                </ImageLoad>
                <div class="title">{{ chat.image.title }}</div>
              </div>
              <p class="message" v-html="formatMessage(chat.message)"></p>
            </div>
        </div>
        <div v-else-if="chat.type === 'event' && chat.eventOption.type === 'cek-literata'" v-bind:key="chat.historyID"
          class="chat-bubble same-actor event bot"
          :class="{
            'play-animation' : chat.playAnimation
          }"
        >

          <div class="profile-picture">
            <img :src="icons[chat.user.toLowerCase()]" width="32" />
          </div>
          <div class="content share-wrapper" >
            <div>{{ userSettings['share-title'] }}</div>
            <div class="share-article">
              <a :href="userSettings['share-article-target']" target="_blank">
              <div class="image">
                <ImageLoad @onLoad="$emit('imageReady')">
                  <!-- <img slot="image" src="@/assets/images/social-share.jpg" class="img-fluid" /> -->
                  <img slot="image" :src="userSettings['share-image']" :alt="userSettings['share-description'] " class="img-fluid" />
                </ImageLoad>
              </div>
              <div class="title">{{ userSettings['share-description'] }} </div>
              <div class="link" v-html="userSettings['share-subtitle']"></div>
              </a>
            </div>
          </div>
        </div>
        <div v-else-if="chat.type === 'event' && chat.eventOption.type === 'score'" v-bind:key="chat.historyID"
          class="chat-bubble same-actor event bot"
          :class="{
            'play-animation' : chat.playAnimation
          }"
        >

          <div class="profile-picture">
            <img :src="icons[chat.user.toLowerCase()]" width="32" />
          </div>
          <div class="content score-wrapper">
            <!-- <div>{{ userSettings['your-score'] }} </div> -->
            <div class="score">
              <!-- <div class="sticker">
                <img src="@/assets/images/happy-smile.png" />
              </div> -->
              <div class="medal">
                <img src="@/assets/images/medal-3.png" v-if="chat.finalScore >= 100" />
                <img src="@/assets/images/medal-2.png" v-else-if="chat.finalScore > 50" />
                <img src="@/assets/images/medal-1.png" v-else-if="chat.finalScore > 0" />
                <img src="@/assets/images/medal-0.png" v-else />
              </div>
              <div class="number">
                {{ chat.finalScore }}%
              </div>
              <div class="button d-none"  @click.prevent="reload()">
                <img src="@/assets/images/repeat.png" />
                <div><a href="#" @click.prevent="reload()"><div>{{ userSettings.play }}</div><div>{{ userSettings.again }}</div></a></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else-if="chat.type === 'event' && chat.eventOption.type === 'interstital'" v-bind:key="chat.historyID+'interstital'"
          class="chat-bubble same-actor event bot"
          :class="{
            'play-animation' : chat.playAnimation
          }"
        >
          <interstital :current_chat_object="chat" :gameSkin="gameSkin" :gameMode="gameMode" :storeBadgeJson="storeBadgeJson"></interstital>
        </div>
        <div v-else-if="chat.type === 'event' && chat.eventOption.type === 'avatar-selection'" v-bind:key="chat.historyID+'avatar-selection'"
          class="chat-bubble answer bot"
          :class="{
            'play-animation' : chat.playAnimation
          }"
        >
          <div class="content avatar-chat">
            <a class="action-check">
              <img :src="selectedAvatar.url" class="d-block reopen-badge"/>
            </a>
            <p class="message center">
              {{ userSettings['my-character'] }}
            </p>
          </div>
        </div>
        <div v-else class="chat-bubble bot" :class="[
          chat.user.toLowerCase(),
          chat.lastChatUser.toLowerCase() == chat.user.toLowerCase() ? 'same-actor' : '',
          chat.playAnimation ? 'play-animation' : ''
        ]" v-bind:key="chat.historyID + 'chatalt'" >
          <div class="profile-picture">
            <img :src="icons[chat.user.toLowerCase()]" width="32" />
          </div>
          <div class="content">
            <div class="actor">{{ userSettings[chat.user] }}</div>
            <template v-if="chat.image.thumb">
              <div class="image">
                <ImageLoad @onLoad="$emit('imageReady')">
                  <b-img :src="chat.image.thumb" slot="image" fluid :alt="getField(chat, 'image.thumb_caption', '')" @click.prevent="$emit('viewMedia', chat.image)" />
                </ImageLoad>
                <template v-if="chat.image.thumb_caption === 'show-interstitial'">
                  <interstital :current_chat_object="chat"></interstital>
                </template>
                <template v-else-if="chat.image.mobile || chat.image.tablet">
                  <a href="#" class="action-check" title="check" @click.prevent="$emit('viewMedia', chat.image)">
                    <img src="@/assets/images/icon-check-new.svg" alt="check" />
                  </a>
                </template>
              </div>
            </template>
            <div v-if="chat.chatType === 'sticker'">
              <Stickers :stickerName="chat.sticker.toString()"> </Stickers>
            </div>
            <p class="message" v-html="formatMessage(chat.message)"></p>
          </div>
        </div>
      </template>
      <div class="bubble-loading-wrapper" v-if="showChatBubble">
        <template v-if="gameSkin === 'sri'">
          <BubbleLoadingMsri />
        </template>
        <template v-else>
          <BubbleLoading />
        </template>
      </div>
    </div>
</template>

<script>

import ImageLoad from '@/components/ImageLoad.vue'
import BubbleLoading from '@/components/BubbleLoading.vue'
import BubbleLoadingMsri from '@/components/BubbleLoading-msri.vue'
import Stickers from '@/components/Stickers'
import Interstital from '@/components/Interstital.vue'

export default {
  name: 'ChatBubble',
  props: {
    userSettings: {
      required: true
    },
    chatHistories: {
      required: true
    },
    playerName: {
      required: true
    },
    score: {
      required: true
    },
    max_point: {
      required: true
    },
    roomInfo: null,
    gameSkin: {
      type: String,
      required: true
    },
    gameMode: {
      type: String,
      required: true
    },
    storeBadgeJson: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      chatscreen: true
      // icons: {
      //   ayah: require('./../assets/images/profile-picture/ico-ayah.png'),
      //   ibu: require('./../assets/images/profile-picture/ico-ibu.png'),
      //   eka: require('./../assets/images/profile-picture/ico-eka.png'),
      //   adik: require('./../assets/images/profile-picture/ico-adik.png'),
      //   om: require('./../assets/images/profile-picture/ico-om.png'),
      //   tante: require('./../assets/images/profile-picture/ico-tante.png')
      // }
    }
  },
  components: {
    Stickers,
    ImageLoad,
    BubbleLoading,
    BubbleLoadingMsri,
    Interstital
  },
  computed: {
    icons: function () {
      return this.$store.getters['actors/getAvatars']
    },
    finalScore: function () {
      return ((this.score / this.max_point) * 100)
    },
    chats: function () {
      return this.chatHistories
    },
    showChatBubble: function () {
      return this.$store.getters.getShowChatLoading
    },
    selectedAvatar () {
      return this.$store.getters['gamePlay/getAvatar']
    }
  },
  methods: {
    reload () {
      window.history.go()
    },
      /**
       * Pass a nested object and field to gracefully return the value
       * @param obj
       * @param field
       * @param default_value
       * @returns {*}
       */
        getField (obj, field, defaultValue) {
          const separator = '.'
          const properties = Array.isArray(field) ? field : field.split(separator)
          const val = properties.reduce((prev, curr) => prev && prev[curr], obj)

          if (!val) {
              return defaultValue
          }

          return val
      },

      interstitalClick: function (chat) {
        this.chatscreen = false
      },

      formatMessage (str) {
          str = str.replaceAll('[blue]', '<span class="blue">')
          str = str.replaceAll('[/blue]', '</span>')

          str = str.replaceAll('[link]', '<span class="link">')
          str = str.replaceAll('[/link]', '</span>')

          str = str.replaceAll('[i]', '<i>')
          str = str.replaceAll('[/i]', '</i>')

          str = str.replaceAll('[b]', '<strong>')
          str = str.replaceAll('[/b]', '</strong>')

          str = str.replaceAll('[br]', '<br />')

          str = str.replaceAll('[ul]', '<ul>')
          str = str.replaceAll('[/ul]', '</ul>')
          str = str.replaceAll('[ol]', '<ol>')
          str = str.replaceAll('[/ol]', '</ol>')
          str = str.replaceAll('[li]', '<li>')
          str = str.replaceAll('[/li]', '</li>')

          str = str.replaceAll('[center]', '<div style="text-align: center">')
          str = str.replaceAll('[/center]', '</div>')

          str = str.replaceAll('[url]', '<a class="link-url" href="')
          str = str.replaceAll('[text]', '" target="_blank">')
          str = str.replaceAll('[/url]', '</a>')

          // /**
          //  * make bold like mention to player
          //  */
          // if (this.getField(this.gameStatus, 'playerName', false)) {
          //   str = str.replace('@player', '<strong>@' + this.gameStatus.playerName + '</strong>')
          // }

          return str
      }

  }
}
</script>
