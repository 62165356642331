<template>
  <div>
      <div>
        <button @click.prevent="addBadge">Add</button>
      </div>
      <draggable
            v-model="badges"
            group="people"
            v-if="badges"
          >
      <div class="item" v-for="(badge, index) in badges" v-bind:key="index">
        <div class="card mb-3 cms-room-form">
          <div class="room-form-content">
            <div class="form-group room-content">
              <div class="left">
                <label>Label:</label>
              </div>
              <div class="right">
                <input type="text" placeholder="Room Name" v-model="badge.name" />
              </div>
            </div>
            <div class="form-group room-content">
              <div class="left">
                <label>Slug:</label>
              </div>
              <div class="right">
                <input type="text" placeholder="Room Slug" v-model="badge.slug"  />
              </div>
            </div>
          </div>
          <div class="room-form-content">
            <div class="form-group room-content">
              <div class="left">
                <label>Icon:</label>
              </div>
              <div class="right">
                <input type="text" placeholder="Room Icon" v-model="badge.icon">
              </div>
            </div>
            <div class="form-group room-content">
              <div class="left">
                <label>Always show?</label>
              </div>
              <div class="right">
                <input class="radio-button" type="radio" value="1" v-model="badge.always_show" /><label class="radio-selection" for="true">Yes</label>
                <input class="radio-button" type="radio" value="0" v-model="badge.always_show" /><label class="radio-seelction" for="false">No</label>
              </div>
            </div>
          </div>
          <div class="room-form-content on-right">
            <div class="form-group room-content">
              <div class="left">
                <label>Hide Score?</label>
              </div>
              <div class="right">
                <input class="radio-button" type="radio" value="1" v-model="badge.hide_score" /><label class="radio-selection" for="true">Yes</label>
                <input class="radio-button" type="radio" value="0" v-model="badge.hide_score" /><label class="radio-seelction" for="false">No</label>
              </div>
            </div>
          </div>
          <div class="room-form-content">
            <div class="form-group room-content bottom">
              <img class="icon" :src="badge.icon" />
              <div class="delete">
                <button
                  v-if="badges.length > 1"
                  @click.prevent="remove(index)"
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </draggable>
      <div class="mt-5">
        <button @click.prevent="save">Save</button>
      </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data () {
    return {
      badges: []
    }
  },
  async mounted () {
    this.refresh()
  },
  methods: {
    addBadge () {
      this.badges.push({
      })
    },
    async refresh () {
      await this.$store.dispatch('rooms/get')
      this.badges = this.$store.getters['rooms/getRooms']
    },
    async save () {
      await this.$store.dispatch('rooms/update', { rooms: this.badges })
      this.refresh()
      this.$bvToast.toast('Success!', {
          autoHideDelay: 4000,
          variant: 'success'
      })
    },
    createBadgeSlug (index) {
      let str = this.badges[index].name
      str = str.replace(/^\s+|\s+$/g, '') // Trim whitespace from beginning and end of string
      str = str.toLowerCase() // Convert the string to lowercase
      str = str.replace(/[^a-z0-9 -]/g, '') // Remove any characters that are not alphanumeric, hyphens, or spaces
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Remove duplicate hyphens
      this.badges[index].slug = str
    },
    remove (index) {
      this.badges.splice(index, 1)
      this.$bvToast.toast('Remember to hit save!', {
          autoHideDelay: 4000,
          variant: 'info'
      })
    }
  }
}
</script>
