<template>
  <div>
      <div>
        <button @click.prevent="addBadge">Add</button>
      </div>
      <draggable
            v-model="badges"
            group="people"
            v-if="badges"
            @end="onEnd"
          >
      <div class="item" v-for="(badge, index) in badges" v-bind:key="index">
        <div class="card cms-actor-form">
          <div class="actor-content">
            <label>Label:</label><input type="text" placeholder="Actor Name" v-model="badge.name" />
          </div>
          <div class="actor-content">
            <label>Slug:</label><input type="text" placeholder="Actor Slug" v-model="badge.slug" />
          </div>
          <div class="actor-content">
            <label>Icon:</label><input type="text" placeholder="Actor Icon" v-model="badge.icon">
          </div>
          <img :src="badge.icon" style="max-width: 100px; display: block;" />
          <div class="actor-content">
            <button
              v-if="badges.length > 1"
              @click.prevent="remove(index)"
            >
              Delete
            </button>
          </div>
        </div>
      </div>
    </draggable>
      <div class="mt-5">
        <button @click.prevent="save">Save</button>
      </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data () {
    return {
      badges: []
    }
  },
  async mounted () {
    this.refresh()
  },
  methods: {
    addBadge () {
      this.badges.push({
      })
    },
    async refresh () {
      await this.$store.dispatch('actors/get')
      this.badges = this.$store.getters['actors/getActors']
    },
    async save () {
      await this.$store.dispatch('actors/update', { actors: this.badges })
      this.$bvToast.toast('Success!', {
          autoHideDelay: 4000,
          variant: 'success'
      })
    },
    createBadgeSlug (index) {
      let str = this.badges[index].name
      str = str.replace(/^\s+|\s+$/g, '') // Trim whitespace from beginning and end of string
      str = str.toLowerCase() // Convert the string to lowercase
      str = str.replace(/[^a-z0-9 -]/g, '') // Remove any characters that are not alphanumeric, hyphens, or spaces
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Remove duplicate hyphens
      this.badges[index].slug = str
    },
    remove (index) {
      this.badges.splice(index, 1)
      this.$bvToast.toast('Remember to hit save!', {
          autoHideDelay: 4000,
          variant: 'info'
      })
    }
  }
}
</script>
