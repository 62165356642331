<template>
  <b-form-select :value="value" @input="handleInput" size="sm">
    <template v-for="(room) in gameRooms">
      <b-form-select-option :value="room.value" v-bind:key="room.value + Math.random()">{{ room.text }}</b-form-select-option>
    </template>
  </b-form-select>
</template>
<script>
import Base from '@/mixins/base.js'

export default {
  props: ['value'],
  mounted () {
    this.doTranslate()
  },
  methods: {
      handleInput (value) {
          this.$emit('input', value)
      },
      doTranslate () {
        // this.roomOptions[0].text = this.getField(this.userSettings, 'ayah', 'Ayah')
        // this.roomOptions[1].text = this.getField(this.userSettings, 'ibu', 'Ibu')
        // this.roomOptions[2].text = this.getField(this.userSettings, 'om', 'Om')
        // this.roomOptions[3].text = this.getField(this.userSettings, 'tante', 'Tante')
        // this.roomOptions[4].text = this.getField(this.userSettings, 'adik', 'Adik')
        // this.roomOptions[5].text = this.getField(this.userSettings, 'eka', 'Eka')
      }
  },
  mixins: [
    Base
  ],
  watch: {
    userSettings: function () {
      this.doTranslate()
    }
  },
  computed: {
    gameRooms: function () {
      return this.roomOptions
    }
  }
}
</script>
