var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"content chat-reopen-interstitial",on:{"click":function($event){$event.preventDefault();return _vm.playInterstital.apply(null, arguments)}}},[(_vm.current_chat_object.eventOption.value === 'correct')?[_c('a',{staticClass:"action-check",attrs:{"href":"#","title":"check"}},[_c('img',{staticClass:"d-block reopen-badge",attrs:{"src":_vm.$store.getters['badges/getFromSlug'](
                        _vm.$data_get(_vm.current_chat_object, 'eventOption.badge')
                      ).url,"alt":"check"}})]),_c('p',{staticClass:"message center"},[_c('strong',[_vm._v(" "+_vm._s(_vm.$store.getters["badges/getFromSlug"](_vm.$data_get(_vm.current_chat_object, "eventOption.badge")).name)+" ")])])]:(_vm.current_chat_object.eventOption.value === 'wrong')?[(
              _vm.$data_get(_vm.current_chat_object, 'eventOption.badge', false)
            )?_c('img',{staticClass:"reopen-badge",attrs:{"src":_vm.$store.getters['badges/getFromSlug'](
                    _vm.$data_get(_vm.current_chat_object, 'eventOption.badge')
                  ).url}}):_c('img',{staticClass:"reopen-badge",attrs:{"src":require("@/assets/images/wrong-interstital-3.png")}})]:_vm._e(),[_c('p',{staticClass:"message center"},[_vm._v(_vm._s(_vm.$data_get(_vm.userSettings, 'lihat-interstital', '-Lihat Interstitial-')))])]],2),(_vm.active)?_c('div',{staticClass:"interstital-modal-wrapper",class:{
    'wrong':_vm.wrong
  }},[_c('div',{staticClass:"interstital-modal"},[_c('div',{staticClass:"head"},[(_vm.current_chat_object.eventOption.value === 'correct')?[_c('LottieCorrect'),(
              _vm.$data_get(_vm.current_chat_object, 'eventOption.badge', false)
            )?[_c('div',{staticClass:"img-wrapper"},[(_vm.hasAnimation)?_c('BadgeAnimation',{attrs:{"animatedBadge":_vm.animationBadge}}):_vm._e(),_c('img',{staticClass:"d-block",attrs:{"src":_vm.$store.getters['badges/getFromSlug'](
                    _vm.$data_get(_vm.current_chat_object, 'eventOption.badge')
                  ).url}})],1),_c('div',{staticClass:"achivement-wrapper"},[_c('h5',[_vm._v(_vm._s(_vm.userSettings["achievement-unlocked"]))]),_c('h2',[_vm._v(" "+_vm._s(_vm.$store.getters["badges/getFromSlug"]( _vm.$data_get(_vm.current_chat_object, "eventOption.badge") ).name)+" ")])])]:[_c('div',{staticClass:"img-wrapper"},[_c('img',{staticClass:"d-block",attrs:{"src":require("@/assets/images/mark-good.png"),"title":_vm.userSettings['you-are-right'],"alt":_vm.userSettings['you-are-right']}})]),_c('div',{staticClass:"achivement-wrapper"},[_c('h5',[_vm._v(_vm._s(_vm.userSettings["achievement-unlocked"]))]),_c('h2',[_vm._v(_vm._s(_vm.userSettings["you-are-right"]))])])]]:[_c('LottieWrong'),_c('div',{staticClass:"img-wrapper"},[(_vm.hasAnimation)?_c('BadgeAnimation',{attrs:{"animatedBadge":_vm.animationBadge ||  _vm.gameSkin !== 'sri'}}):_c('div',[(_vm.gameSkin !== 'sri')?_c('BadgeWrong'):_vm._e()],1),(
              _vm.$data_get(_vm.current_chat_object, 'eventOption.badge', false)
            )?_c('img',{staticClass:"d-block",attrs:{"src":_vm.$store.getters['badges/getFromSlug'](
                    _vm.$data_get(_vm.current_chat_object, 'eventOption.badge')
                  ).url,"title":_vm.userSettings['you-are-wrong'],"alt":_vm.userSettings['you-are-wrong']}}):_c('img',{staticClass:"d-block",attrs:{"src":require("@/assets/images/wrong-interstital-3.png"),"title":_vm.userSettings['you-are-wrong'],"alt":_vm.userSettings['you-are-wrong']}})],1),_c('div',{staticClass:"achivement-wrapper"},[_c('h5',[_vm._v(_vm._s(_vm.userSettings["header-wrong-interstital"]))]),_c('h2',[_vm._v(_vm._s(_vm.userSettings["you-are-wrong"]))])])]],2),_c('div',{staticClass:"content reopen-badge-content",attrs:{"id":"interstital-content"}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.$formatMessage(_vm.current_chat_object.message))}})]),_c('div',{staticClass:"action"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-sm-12 col-md-6 offset-md-3"},[_c('button',{staticClass:"button mt-3 mb-2",attrs:{"title":_vm.userSettings['continue']},on:{"click":function($event){$event.preventDefault();return _vm.closeInterstital.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.userSettings["continue"])+" ")])])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }