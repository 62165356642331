<template>
    <div id="lottie">
        <lottie
            :options="defaultOptions"
            v-on:animCreated="handleAnimation"
            :height="'auto'"
            />
    </div>
</template>
<script>
import * as animationData from '@/assets/confetti-true.json'
import Lottie from 'vue-lottie'

export default {
  name: 'Background',
  data () {
    return {
      defaultOptions: { animationData: animationData.default, autoplay: true, loop: false },
      animationSpeed: 1,
      maxWidth: 100
    }
  },
  components: {
      lottie: Lottie
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
      this.anim.setSpeed(this.animationSpeed)
    },

    stop: function () {
      this.anim.stop()
    },

    play: function () {
      this.anim.play()
    },

    pause: function () {
      this.anim.pause()
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed)
    }
  }
}
</script>
<style scoped>
    #lottie svg{
        background: #2fc7be;
    }

    #lottie{
        background: none;
        /* width:100%;
        height: 100%; */
        display:block;
        /* overflow: auto; */
        transform: translate3d(0,0,0) translateX(-50%);
        text-align: center;
        opacity: 1;
        position: absolute;
        max-width: 450px;
        left: 50%;
        z-index: -1;
        top: 120px;
    }

    #lottie > div {
        margin-top: -28% !important;
        /* transform: translateX(-50%); */
        width: 100%  !important;
        height: auto  !important;
        overflow: auto !important;
    }

    #lottie svg > g > g:first-child > g > path{
      fill: #2fc7be;
    }
</style>
