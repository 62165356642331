<template>
    <div clas="lottie">
        <lottie
            :options="defaultOptions"
            v-on:animCreated="handleAnimation"
            :width="30"
            />
    </div>
</template>
<script>
import * as animationData from '@/assets/bubble-loading-msri.json'
import Lottie from 'vue-lottie'

export default {
  name: 'BubbleLoading',
  data () {
    return {
      defaultOptions: { animationData: animationData.default },
      animationSpeed: 1
    }
  },
  components: {
      lottie: Lottie
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
      this.anim.setSpeed(this.animationSpeed)
    },

    stop: function () {
      this.anim.stop()
    },

    play: function () {
      this.anim.play()
    },

    pause: function () {
      this.anim.pause()
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed)
    }
  }
}
</script>
