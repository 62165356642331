<template>
  <div class="preview-media active"  v-if="active">
    <div class="top-nav">
      <a href="#" class="button" @click.prevent="$emit('closeViewMedia')">
        <img src="@/assets/images/icon-back.svg" :alt="userSettings['back']" />
        {{ userSettings['back'] }}
      </a>
      <!-- <div class="title">{{ previewMedia.title }}</div> -->
    </div>
    <div class="content" ref="mediaDetail">
      <ImageLoad @onComplete="$emit('scrollCanvas')">
        <b-img slot="image" :src="previewMedia.full" v-bind:id="'mobile'+previewMedia.id" :title="getField(previewMedia, 'full_caption')" :alt="getField(previewMedia, 'full_caption')" fluid class="d-block d-md-none" />
        <div class="loading d-block d-md-none" slot="preloader">
          <b-spinner type="grow" label="Spinning"></b-spinner>
          <!-- <img src="@/assets/images/loading.gif" width="100" /> -->
        </div>
      </ImageLoad>
      <ImageLoad >
        <b-img slot="image" :src="previewMedia.tablet" v-bind:id="'tablet'+previewMedia.id" :title="getField(previewMedia, 'tablet_caption')" :alt="getField(previewMedia, 'tablet_caption')" fluid class="d-none d-md-block" />
        <div class="loading d-none d-md-block"  slot="preloader">
          <b-spinner type="grow" label="Spinning"></b-spinner>
          <!-- <img src="@/assets/images/loading.gif" width="100" /> -->
        </div>
      </ImageLoad>

    </div>
  </div>
</template>

<script>
import ImageLoad from '@/components/ImageLoad.vue'
import Base from '@/mixins/base'

export default {
  props: ['previewMedia', 'active'],
  components: {
    ImageLoad
  },
  mixins: [Base]
}
</script>
