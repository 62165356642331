<template>
    <div id="lottie">
        <lottie v-if="defaultOptions.animationData"
            :options="defaultOptions"
            v-on:animCreated="handleAnimation"
            :height="'auto'"
            />
    </div>
</template>
<script>
// import * as animationData from '@/assets/badge-trustworthy-source.json'
import Lottie from 'vue-lottie'

export default {
  name: 'Background',
  data () {
    return {
      defaultOptions: { animationData: null, autoplay: true, loop: false },
      animationSpeed: 1,
      maxWidth: 100
    }
  },
  props: {
    animatedBadge: {
      type: Object,
      required: true
    }
  },
  async created () {
    console.log(this.animatedBadge, 'created')
    this.getAnimationJson(this.animatedBadge)
  },
  components: {
      lottie: Lottie
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
      this.anim.setSpeed(this.animationSpeed)
    },

    stop: function () {
      this.anim.stop()
    },

    play: function () {
      this.anim.play()
    },

    pause: function () {
      this.anim.pause()
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed)
    },
    async getAnimationJson (animatedBadge) {
      this.defaultOptions.animationData = this.animatedBadge.animationData
    }
  }
}
</script>
<style scoped>
    #lottie svg{
        background: #2fc7be;
    }

    #lottie{
        background: none;
        width:100%;
        /* height: 100%; */
        display:block;
        /* overflow: auto; */
        transform: translate3d(0,0,0) translateX(-50%);
        text-align: center;
        opacity: 1;
        position: absolute;
        max-width: 140px;
        left: 50%;
        z-index: 1;
        top: 58px;
        @media screen and (max-width: 500px) {
          max-width: 150px;
        }
    }

    #lottie > div {
        margin-top: -28% !important;
        /* transform: translateX(-50%); */
        width: 100%  !important;
        height: auto  !important;
        overflow: auto !important;
    }

    #lottie svg > g > g:first-child > g > path{
      fill: #2fc7be;
    }
</style>
