<template>
    <b-modal :id="id" hide-footer size="xl" title="">
        <div class="container media-manager">
            <div class="large-12 medium-12 small-12 cell">
                <h1>Media Manager</h1>
                <label>File
                    <input type="file" id="file" ref="file"/>
                </label>
                <button v-on:click="uploadFile()">Upload</button>
                <br />
                <b-spinner v-if="this.show" label="Loading..."></b-spinner>
                <div class="container assets">
                    <div class="row">
                        <template v-for="asset in assets">
                            <div class="col-3 asset" :key="asset.ID">
                                <div>
                                    <div class="img-wrapper">
                                        <b-img-lazy fluid  :src="asset.url" />
                                    </div>
                                    <span>{{ asset.title }}</span>
                                </div>
                                <div class="button-wrapper">
                                    <b-button @click.prevent="deleteFile(asset)" class="danger">Delete</b-button>
                                    <b-button @click.prevent="selectAsset(asset)">Select</b-button>
                                </div>
                            </div>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import Base from '@/mixins/base.js'
import { uuid } from 'vue-uuid'

  export default {
    name: 'MediaManagerV2',
    data () {
        return {
            file: '',
            assets: [],
            show: true,
            resolvePromise: null,
            rejectPromise: null,
            id: uuid.v1()
        }
    },
    mixins: [
        Base
    ],
    async created () {
        await this.getBaseUrl()
        this.getFiles()
    },
    methods: {
        pick () {
            return new Promise((resolve, reject) => {
                this.$bvModal.show(this.id)

                this.resolvePromise = resolve
                this.rejectPromise = reject
            })
        },
        async makeCall ($arg) {
          const baseURL = this.baseURL
          console.log(this.baseURL, 'baseURL')

            const result = await this.$http.post(baseURL + '/moonshot-game', $arg)
                .then((response) => {
                    return response
                })
            this.show = false
            return result
        },
        selectAsset: function (asset) {
            this.$bvModal.hide(this.id)
            this.resolvePromise(asset)
        },
        deleteFile: function (asset) {
            const r = confirm('Are you sure to delete?')
            if (r) {
                const args = {
                    action: 'delete_file',
                    id: asset.ID,
                    'game-slug': this.getGameSlug()
                }
                this.makeCall(args).then((res) => {
                    if (res.data.success) {
                        alert('Delete Succesful')
                        this.getFiles()
                    }
                })
            }
        },
        uploadFile: function () {
            this.file = this.$refs.file.files[0]
            const formData = new FormData()
            formData.append('file', this.file)
            formData.append('action', 'upload_file')
            formData.append('game-slug', this.getGameSlug())
            this.show = true
            this.$refs.file.value = ''
            this.makeCall(formData).then((res) => {
                this.getFiles()
                this.show = false
            })
        },
        getFiles: function () {
            const args = {
                action: 'media_browse',
                'game-slug': this.getGameSlug()
            }
            this.makeCall(args).then((res) => {
                if (res.data.success) {
                    this.assets = res.data.data
                }
            })
        }
    }
}
</script>
