<template>
    <div class="tree-container">
        <ul v-if="isLoaded" class="tree">
            <Node :label="tree.label"
            :children="tree.nodes"
            :id="tree.id"
            />
        </ul>
    </div>
</template>
<script>
/* eslint-disable */
import Node from '../components/Node.vue'

export default {
    components: { Node},
    name: "Tree",
    data(){
        return{ 
            tree: {},
            isLoading: true,
            isLoaded: false
        }
    },
    created(){
        this.initialLoad();
    },

    methods: {

        initialLoad() {
            this.$http.post('https://moonshot.local/moonshot-game')
                .then((response) => {
                    console.log(response.data.nodes[0])
                    this.tree = response.data.nodes[0]
                    this.isLoading = false
                    this.isLoaded = true
                })
        }
    }
}
</script>