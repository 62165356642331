<template>
  <div>
      <div>
        <button @click.prevent="addBadge">Add</button>
      </div>
      <draggable
            v-model="badges"
            group="people"
            v-if="badges"
          >
      <div class="item" v-for="(badge, index) in badges" v-bind:key="index">
        <div class="card mb-3 cms-avatar-form">
          <div class="image-avatar">
            <img :src="badge.url" style="max-width: 100px; display: block;" />
          </div>
          <div class="avatar-content">
            <div class="avatar-inner">
              <label>Avatar Url Image:</label>
              <div><input type="text" placeholder="Avatar Image Url" v-model="badge.url" style="width: 300px" /></div>
            </div>
            <div class="avatar-inner">
              <label>Avatar Title:</label>
              <div><input type="text" placeholder="title mainly used for tracking" v-model="badge.title" style="width: 300px" /></div>
            </div>
            <div class="avatar-inner">
              <label>Avatar Url Animation:</label>
              <div><input type="text" placeholder="Avatar JSON Url" v-model="badge.json" style="width: 300px" /></div>
            </div>
          </div>
          <button
              v-if="badges.length > 1"
              @click.prevent="remove(index)"
            >
              Delete
            </button>
        </div>
      </div>
    </draggable>
      <div class="mt-5">
        <button @click.prevent="save">Save</button>
      </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data () {
    return {
      badges: []
    }
  },
  async mounted () {
    this.refresh()
  },
  methods: {
    addBadge () {
      this.badges.push({
      })
    },
    async refresh () {
      await this.$store.dispatch('avatars/get')
      this.badges = this.$store.getters['avatars/getAvatars']
    },
    async save () {
      await this.$store.dispatch('avatars/update', { avatars: this.badges })
      this.$bvToast.toast('Success!', {
          autoHideDelay: 4000,
          variant: 'success'
      })
    },
    remove (index) {
      this.badges.splice(index, 1)
      this.$bvToast.toast('Remember to hit save!', {
          autoHideDelay: 4000,
          variant: 'info'
      })
    }
  }
}
</script>
