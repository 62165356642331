<template>
    <div class="sticker">
        <lottie
            :options="defaultOptions"
            :height="0"
            :width="0"
            v-on:animCreated="handleAnimation"
            />
    </div>
</template>

<script>
import Lottie from 'vue-lottie'
export default {
  name: 'Sticker',
  props: {
    animation: {
      required: true
    },
    loop: {
      required: false
    },
    noPlay: {
      type: Boolean,
      default: false
    },
    pending: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      defaultOptions: {
        animationData: this.animation.default,
        loop: (typeof this.loop !== 'undefined') ? this.loop : false
      },
      animationSpeed: 1
      // maxWidth: 1366
    }
  },
  watch: {
    animation: function () {
      this.animationData = this.animation.default
      this.handleAnimation(this.anim)
      // console.log('fired!')
    }
  },
  components: {
      lottie: Lottie
  },
  methods: {
    handleAnimation: function (anim) {
      this.anim = anim
      // console.log('fired2!')

      if (this.noPlay) {
        this.anim.stop()
      } else {
        this.anim.setSpeed(this.animationSpeed)
      }
    },

    stop: function () {
      this.anim.stop()
    },

    play: function () {
      this.anim.play()
    },

    pause: function () {
      this.anim.pause()
    },

    onSpeedChange: function () {
      this.anim.setSpeed(this.animationSpeed)
    }
  }
}
</script>
