<template>
    <li>
      <span>{{label}}</span>
      <template v-if="children">
        <ul>
          <node
            v-bind:key="child.key"
            v-for="child in children"
            :label="child.label"
            :id="child.id"
            :children="child.nodes"
          />
        </ul>
      </template>
    </li>
</template>
<script>

export default {
  props: ['label', 'children', 'id'],
  name: 'node'
}
</script>
