<template>
  <div class="about">
    <h1>Register</h1>
    <input type="text" placeholder="email" /> <br />
    <input type="password" placeholder="password" /> <br />
    <button>Register an account</button>
    <p><router-link to="/login">Login Instead?</router-link></p>
    <div class="chat-room">
            {{ $i18n.t('family') }}
            {{ $i18n.locale }}
            <select v-model="$i18n.locale">
              <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
            </select>
          </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'Register',
  components: {
  },
  data () {
    return {
        langs: ['en', 'id']
    }
  }
}
</script>
