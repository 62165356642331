<template>
  <div class="share-result-outer-wrapper">
    <div class="share-result-wrapper">
      <div class="share-result-content">
        <div class="card">
          <div class="badge">
            <div class="left">
              <img :src="selectedAvatar.url" class="rounded-circle img-fluid" />
              <h5 v-if="endMode !== 'v2'" class="player-name">
                {{ playerName }}
              </h5>
            </div>
            <div class="right">
              <h5 v-html="userSettings['share-page-header']"></h5>
              <h1>{{ playerScore }}</h1>
              <button class="inline button" @click.prevent="restartGame()" v-html="userSettings['share-restart-button']"></button>
            </div>
          </div>
        </div>
        <div v-if="endMode !== 'v2'" class="badges-wrapper">
          <h6 v-html="userSettings['share-text-content']"></h6>
          <div class="social-media">
            <a href="" class="media" @click.prevent="shareOnWhatsapp()">
              <img src="../assets/images/share-whatsapp.svg" class="img-fluid" />
              <span>Whatsapp</span>
            </a>

            <a href="" class="media" @click.prevent="shareOnFacebook()">
              <img src="../assets/images/share-facebook.svg" class="img-fluid" />
              <span>Facebook</span>
            </a>

            <a href="" class="media" @click.prevent="shareOnTwitter()">
              <img src="../assets/images/share-twitter.svg" class="img-fluid" />
              <span>Twitter</span>
            </a>

            <a href="" class="media" @click.prevent="copyLink()">
              <img src="../assets/images/share-link.svg" class="img-fluid" />
              <span>Copy Link</span>
            </a>

          </div>
        </div>
        <div class="action-button">
          <a href="#" class="button inline invert"  @click.prevent="cancelClick()" v-html="userSettings['share-cancel-button']"></a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import buttonSound from '@/assets/sounds/button.wav'
export default {
    name: 'characterSelection',
    props: {
      userSettings: {
        default: {},
        required: false
      }
    },
    data () {
      return {
        soundHandler: {
          button: new Audio(buttonSound)
        }
      }
    },
    methods: {
      restartGame () {
        this.soundHandler.button.addEventListener('ended', () => {
          window.location.reload()
        })
        this.soundHandler.button.play()
      },
      cancelClick () {
        this.soundHandler.button.play()
        this.$emit('goBack')
      },
      copyLink () {
        navigator.clipboard.writeText(this.$store.getters['gamePlay/getShareUrl']).then(() => {
          alert('Link copied to clipboard!')
        }, (err) => {
          console.error('Could not copy text: ', err)
        })
        this.$bus.$emit('track-event', 'game_results_share', {
          method: 'copy link',
          link: this.shareLink
        })
      },
      shareOnWhatsapp () {
        const whatsappLink = `https://wa.me/?text=${this.shareLink}`
        this.$bus.$emit('track-event', 'game_results_share', {
          method: 'whatsapp',
          link: this.shareLink
        })
        window.open(whatsappLink, '_blank')
      },
      shareOnFacebook () {
        const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${this.shareLink}`
        this.$bus.$emit('track-event', 'game_results_share', {
          method: 'facebook',
          link: this.shareLink
        })
        window.open(facebookLink, '_blank')
      },
      shareOnTwitter () {
        const twitterLink = `https://twitter.com/intent/tweet?text=${this.shareLink}`
        this.$bus.$emit('track-event', 'game_results_share', {
          method: 'twitter',
          link: this.shareLink
        })
        window.open(twitterLink, '_blank')
      }
    },
    mounted () {
      const whatsappLink = this.$refs.whatsappLink
      const facebookLink = this.$refs.facebookLink
      const twitterLink = this.$refs.twitterLink
      const copyLink = this.$refs.copyLink
      if (copyLink) {
        copyLink.addEventListener('click', this.copyLink)
      }
      if (whatsappLink) {
        whatsappLink.addEventListener('click', this.shareOnWhatsapp)
      }
      if (facebookLink) {
        facebookLink.addEventListener('click', this.shareOnFacebook)
      }
      if (twitterLink) {
        twitterLink.addEventListener('click', this.shareOnTwitter)
      }

      /**
       * make a GET request in order for the server to render and store the image
       */
      try {
        const instance = this.axios.create({
          baseURL: this.shareLink
        })

        instance.get()
      } catch (err) {
        console.log(err)
      }
    },
    computed: {
      endMode () {
        return this.$store.getters['gameSetting/getEndMode']
      },
      shareLink () {
        return encodeURIComponent(this.$store.getters['gamePlay/getShareUrl'])
      },
      playerName () {
        return this.$store.getters['gamePlay/getName']
      },
      selectedAvatar () {
        return this.$store.getters['gamePlay/getAvatar']
      },
      collectedBadges () {
        return this.$store.getters['gamePlay/getBadges']
      },
      playerScore () {
        return this.$store.getters['gamePlay/getScore']
      }
    }
  }
</script>
