var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-none"},[_vm._v(_vm._s(_vm.roomInfo))]),_vm._l((_vm.chats),function(chat){return [(chat.isNewMessage)?_c('div',{key:'new-'+chat.historyID,staticClass:"text-new"},[_c('div',{staticClass:"line"}),_c('div',{staticClass:"text"},[_vm._v(_vm._s(_vm.userSettings.new))])]):_vm._e(),(chat.type === 'answer')?_c('div',{key:chat.historyID,staticClass:"chat-bubble answer bot",class:{
      'play-animation' : chat.playAnimation
    }},[_c('div',{staticClass:"content",class:chat.className},[(chat.className !== 'neutral')?_c('div',{staticClass:"player-thumb-answer"},[_c('Stickers',{attrs:{"stickerName":chat.className}})],1):_vm._e(),(_vm.getField(chat, 'long_message', false))?[_c('p',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.formatMessage(chat.long_message))}})]:[_c('p',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.formatMessage(chat.message))}})]],2)]):(chat.chatType === 'article')?_c('div',{key:chat.historyID,staticClass:"chat-bubble article bot",class:[
        chat.user.toLowerCase(),
        chat.lastChatUser.toLowerCase() == chat.user.toLowerCase() ? 'same-actor' : '',
        chat.playAnimation ? 'play-animation' : ''
      ]},[_c('div',{staticClass:"profile-picture"},[_c('img',{attrs:{"src":_vm.icons[chat.user.toLowerCase()],"width":"32"}})]),_c('div',{staticClass:"content",class:chat.className,on:{"click":function($event){$event.preventDefault();return _vm.$emit('viewMedia', chat.image)}}},[_c('div',{staticClass:"actor"},[_vm._v(_vm._s(_vm.userSettings[chat.user]))]),_c('div',{staticClass:"thumb"},[_c('ImageLoad',{on:{"onLoad":function($event){return _vm.$emit('imageReady')}}},[_c('b-img',{attrs:{"slot":"image","fluid":"","src":chat.image.thumb,"alt":_vm.getField(chat, 'image.thumb_caption', '')},slot:"image"})],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(chat.image.title))])],1),_c('p',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.formatMessage(chat.message))}})])]):(chat.type === 'event' && chat.eventOption.type === 'cek-literata')?_c('div',{key:chat.historyID,staticClass:"chat-bubble same-actor event bot",class:{
        'play-animation' : chat.playAnimation
      }},[_c('div',{staticClass:"profile-picture"},[_c('img',{attrs:{"src":_vm.icons[chat.user.toLowerCase()],"width":"32"}})]),_c('div',{staticClass:"content share-wrapper"},[_c('div',[_vm._v(_vm._s(_vm.userSettings['share-title']))]),_c('div',{staticClass:"share-article"},[_c('a',{attrs:{"href":_vm.userSettings['share-article-target'],"target":"_blank"}},[_c('div',{staticClass:"image"},[_c('ImageLoad',{on:{"onLoad":function($event){return _vm.$emit('imageReady')}}},[_c('img',{staticClass:"img-fluid",attrs:{"slot":"image","src":_vm.userSettings['share-image'],"alt":_vm.userSettings['share-description']},slot:"image"})])],1),_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.userSettings['share-description'])+" ")]),_c('div',{staticClass:"link",domProps:{"innerHTML":_vm._s(_vm.userSettings['share-subtitle'])}})])])])]):(chat.type === 'event' && chat.eventOption.type === 'score')?_c('div',{key:chat.historyID,staticClass:"chat-bubble same-actor event bot",class:{
        'play-animation' : chat.playAnimation
      }},[_c('div',{staticClass:"profile-picture"},[_c('img',{attrs:{"src":_vm.icons[chat.user.toLowerCase()],"width":"32"}})]),_c('div',{staticClass:"content score-wrapper"},[_c('div',{staticClass:"score"},[_c('div',{staticClass:"medal"},[(chat.finalScore >= 100)?_c('img',{attrs:{"src":require("@/assets/images/medal-3.png")}}):(chat.finalScore > 50)?_c('img',{attrs:{"src":require("@/assets/images/medal-2.png")}}):(chat.finalScore > 0)?_c('img',{attrs:{"src":require("@/assets/images/medal-1.png")}}):_c('img',{attrs:{"src":require("@/assets/images/medal-0.png")}})]),_c('div',{staticClass:"number"},[_vm._v(" "+_vm._s(chat.finalScore)+"% ")]),_c('div',{staticClass:"button d-none",on:{"click":function($event){$event.preventDefault();return _vm.reload()}}},[_c('img',{attrs:{"src":require("@/assets/images/repeat.png")}}),_c('div',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.reload()}}},[_c('div',[_vm._v(_vm._s(_vm.userSettings.play))]),_c('div',[_vm._v(_vm._s(_vm.userSettings.again))])])])])])])]):(chat.type === 'event' && chat.eventOption.type === 'interstital')?_c('div',{key:chat.historyID+'interstital',staticClass:"chat-bubble same-actor event bot",class:{
        'play-animation' : chat.playAnimation
      }},[_c('interstital',{attrs:{"current_chat_object":chat,"gameSkin":_vm.gameSkin,"gameMode":_vm.gameMode,"storeBadgeJson":_vm.storeBadgeJson}})],1):(chat.type === 'event' && chat.eventOption.type === 'avatar-selection')?_c('div',{key:chat.historyID+'avatar-selection',staticClass:"chat-bubble answer bot",class:{
        'play-animation' : chat.playAnimation
      }},[_c('div',{staticClass:"content avatar-chat"},[_c('a',{staticClass:"action-check"},[_c('img',{staticClass:"d-block reopen-badge",attrs:{"src":_vm.selectedAvatar.url}})]),_c('p',{staticClass:"message center"},[_vm._v(" "+_vm._s(_vm.userSettings['my-character'])+" ")])])]):_c('div',{key:chat.historyID + 'chatalt',staticClass:"chat-bubble bot",class:[
      chat.user.toLowerCase(),
      chat.lastChatUser.toLowerCase() == chat.user.toLowerCase() ? 'same-actor' : '',
      chat.playAnimation ? 'play-animation' : ''
    ]},[_c('div',{staticClass:"profile-picture"},[_c('img',{attrs:{"src":_vm.icons[chat.user.toLowerCase()],"width":"32"}})]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"actor"},[_vm._v(_vm._s(_vm.userSettings[chat.user]))]),(chat.image.thumb)?[_c('div',{staticClass:"image"},[_c('ImageLoad',{on:{"onLoad":function($event){return _vm.$emit('imageReady')}}},[_c('b-img',{attrs:{"slot":"image","src":chat.image.thumb,"fluid":"","alt":_vm.getField(chat, 'image.thumb_caption', '')},on:{"click":function($event){$event.preventDefault();return _vm.$emit('viewMedia', chat.image)}},slot:"image"})],1),(chat.image.thumb_caption === 'show-interstitial')?[_c('interstital',{attrs:{"current_chat_object":chat}})]:(chat.image.mobile || chat.image.tablet)?[_c('a',{staticClass:"action-check",attrs:{"href":"#","title":"check"},on:{"click":function($event){$event.preventDefault();return _vm.$emit('viewMedia', chat.image)}}},[_c('img',{attrs:{"src":require("@/assets/images/icon-check-new.svg"),"alt":"check"}})])]:_vm._e()],2)]:_vm._e(),(chat.chatType === 'sticker')?_c('div',[_c('Stickers',{attrs:{"stickerName":chat.sticker.toString()}})],1):_vm._e(),_c('p',{staticClass:"message",domProps:{"innerHTML":_vm._s(_vm.formatMessage(chat.message))}})],2)])]}),(_vm.showChatBubble)?_c('div',{staticClass:"bubble-loading-wrapper"},[(_vm.gameSkin === 'sri')?[_c('BubbleLoadingMsri')]:[_c('BubbleLoading')]],2):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }