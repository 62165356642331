<template>
  <div class="container">
    <h1>To-Do List</h1>
    <h1>{{$route.params.id}}</h1>
    <div id="app">
        <div>
        <TodoNew />
        <TodoList />
        </div>
    </div>
    <img src="~@/assets/logo.png" class="vue-logo" alt="Vue.js Logo" />
    </div>
</template>

<script>
// @ is an alias to /src
import TodoNew from '@/components/TodoNew.vue'
import TodoList from '@/components/Todos.vue'

export default {
    name: 'Todo',

    components: {
        TodoNew,
        TodoList
    }
}
</script>
