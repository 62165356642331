<template>
  <div>
      <div>
        <button @click.prevent="addBadge">Add</button>
      </div>
      <draggable
            v-model="badges"
            group="people"
            v-if="badges"
          >
      <div class="item" v-for="(badge, index) in badges" v-bind:key="index">
        <div class="card mb-3 cms-badge-form">
          <div class="badge-form-content">
            <div class="badge-content">
              <div class="left">
                <label>Label</label>
              </div>
              <div class="right">
                <input type="text" placeholder="Badge Name" @input="createBadgeSlug(index)" v-model="badge.name" />
              </div>
            </div>
            <div class="badge-content">
              <div class="left">
                <label>Slug</label>
              </div>
              <div class="right">
                <input type="text" placeholder="Badge Slug" v-model="badge.slug" disabled />
              </div>
            </div>
          </div>
          <div class="badge-form-content">
            <div class="badge-content">
              <div class="left">
                <label>Icon</label>
              </div>
              <div class="right">
                <input type="text" placeholder="Badge Image URL" v-model="badge.url" />
              </div>
            </div>
            <div class="badge-content">
              <div class="left">
                <label>Description</label>
              </div>
              <div class="right">
                <input
                type="text"
                placeholder="Badge Description"
                v-model="badge.description"
              />
                </div>
            </div>
          </div>
          <div class="badge-form-content animation">
            <div class="badge-content">
              <div class="left">
                <label>Animation</label>
              </div>
              <div class="right">
                <input type="text" placeholder="Badge animation URL" v-model="badge.json" />
              </div>
            </div>
          </div>
          <div class="badge-form-content bottom">
            <img :src="badge.url" style="max-width: 100px; display: block;" />
            <div class="delete">
              <button
                class="delete-badge"
                v-if="badges.length > 1"
                @click.prevent="removeBadge(index)"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </draggable>
      <div class="mt-5">
        <button @click.prevent="save">Save</button>
      </div>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  components: {
    draggable
  },
  data () {
    return {
      badges: []
    }
  },
  async mounted () {
    this.refresh()
  },
  methods: {
    addBadge () {
      this.badges.push({
      })
    },
    createBadgeSlug (index) {
      let str = this.badges[index].name
      str = str.replace(/^\s+|\s+$/g, '') // Trim whitespace from beginning and end of string
      str = str.toLowerCase() // Convert the string to lowercase
      str = str.replace(/[^a-z0-9 -]/g, '') // Remove any characters that are not alphanumeric, hyphens, or spaces
        .replace(/\s+/g, '-') // Replace spaces with hyphens
        .replace(/-+/g, '-') // Remove duplicate hyphens
      this.badges[index].slug = str
    },
    async refresh () {
      await this.$store.dispatch('badges/getBadges')
      this.badges = this.$store.getters['badges/getBadges']
    },
    async save () {
      await this.$store.dispatch('badges/updateBadges', { badges: this.badges })
      this.$bvToast.toast('Success!', {
          autoHideDelay: 4000,
          variant: 'success'
      })
    },
    removeBadge (index) {
      this.badges.splice(index, 1)
      this.$bvToast.toast('Remember to hit save!', {
          autoHideDelay: 4000,
          variant: 'info'
      })
    }
  }
}
</script>
