<template>
  <div class="about">
    <h1>Login</h1>
    <input type="text" placeholder="email" /> <br />
    <input type="password" placeholder="password" /> <br />
    <button @click="login">Login</button>
    <p><router-link to="/register">Create one?</router-link></p>

          <div class="chat-room">
            {{ $i18n.t('family') }}
            {{ $i18n.locale }}
            <select v-model="$i18n.locale">
              <option v-for="(lang, i) in langs" :key="`Lang${i}`" :value="lang">{{ lang }}</option>
            </select>
          </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: 'Home',
  components: {
  },
  methods: {
      login: function () {
          this.$router.replace('home')
      }
  },
  data () {
    return {
        langs: ['en', 'id']
    }
  }
}
</script>
