<template>
  <div class="GameTest v2" v-bind:class="gameSkin">
    <template v-if="gameStatus.welcomeScreen && gameMode !== 'fast' && startGame">
      <template v-if="gameSkin === 'sri'">
        <LoadingMsri />
      </template>
      <template v-else-if="gameSkin === 'glo'">
        <Loading />
      </template>
      <WelcomeScreen2
        v-if="gameStatus.welcomeScreen && gameStatus.welcomeReady"
        :userSettings="userSettings"
        :gameMode="gameMode"
        @playerAnswer="setPlayerName"
      />
      <CharacterSelection
        v-if="gameStatus.characterSelection"
        :userSettings="userSettings"
        :gameMode="gameMode"
        :gameSkin="gameSkin"
        :storeJson="storeJson"
        :showAnimation="showAnimation"
        @backToWelcomeScreen="backToWelcomeScreen"
        @characterSelection="setPlayerCharacter"
      >
      </CharacterSelection>
    </template>

    <template>
      <CharacterSelection
        v-if="gameStatus.characterSelection"
        :userSettings="userSettings"
        :gameMode="gameMode"
        :gameSkin="gameSkin"
        :storeJson="storeJson"
        :showAnimation="showAnimation"
        @characterSelection="closeSelection"
      >
      </CharacterSelection>
    </template>

    <!-- Game -->
    <div
      v-if="!gameStatus.welcomeScreen && !gameStatus.characterSelection"
      class="container-fluid chat-screen"
    >
      <template v-if="gameMode === 'fast'">
        <LoadingMsri v-if="gameSkin === 'sri'" :class="{ 'preload': preLoad }" />
        <LoadingFast v-else :class="{ 'preload': preLoad }" />
      </template>
      <!-- preview media -->
      <preview-media
        @scrollCanvas="scrollCanvas"
        @closeViewMedia="closeViewMedia"
        :active="gameStatus.viewMedia"
        :userSettings="userSettings"
        :previewMedia="previewMedia"
      ></preview-media>
      <div class="row game-area no-gutters">
        <div
          class="col-md-4 col-xl-2 chat-room-list"
          v-bind:class="{ active: currentActiveChatRoom == 'pick-room' }"
        >
          <div class="top-info">
            <!-- Game Score -->
            <div class="game-info">
              <div class="label-wrapper">
                <span class="label" v-if="userSettings['permainan'] !== '-'"
                  >{{ userSettings["permainan"] }}
                </span>
                <template v-if="gameSkin === 'sri'">
                  <span class="label" v-if="userSettings['permainan-2'] !== '-'"
                    >{{ userSettings["permainan-2"] }}
                  </span>
                </template>
              </div>
              <img :src="userSettings['mini-logo']" class="d-block" />
              <span class="label" v-if="userSettings['permainan-alt'] !== '-'">
                {{ userSettings["permainan-alt"] }}</span
              >
            </div>
          </div>
          <!-- Chat Room List -->
          <template v-for="(room, index) in roomChatInfo">
            <a
              href="#"
              v-bind:key="room.title"
              v-if="
                chatHistories[index].length ||
                roomChatInfo[index].always_show === '1'
              "
              class="chat-room"
              v-bind:class="{ active: currentActiveChatRoom == index }"
              @click.prevent="changeRoom(index)"
            >
              <div class="chat-info">
                <img :src="room.icon" class="rounded-circle icon" />
                <div class="description">
                  <div class="title">{{ room.title }}</div>
                </div>
              </div>
            </a>
          </template>
          <div
            class="chat-room"
            v-if="adminAccess"
            @click.prevent="switchToEditor"
          >
            <div class="chat-info">
              <img
                src="@/assets/images/admin.jpg"
                class="rounded-circle icon"
              />
              <div class="description">
                <div class="title">Admin access</div>
                <div class="description">Click here to access CMS</div>
                <div class="description">
                  Player Score: {{ gameStatus.score }}
                </div>
                <div class="description">
                  Max progress count: {{ max_progress }}
                </div>
              </div>
            </div>
          </div>
          <div class="chat-room" v-if="adminAccess">
            <div class="chat-info">
              <img
                src="@/assets/images/admin.jpg"
                class="rounded-circle icon"
              />
              <div class="description">
                <div class="title">Change Speed</div>
                <div class="description">
                  <b-form-select v-model="AdminTweak.gameSpeed" size="sm">
                    <b-form-select-option value="100"
                      >100ms</b-form-select-option
                    >
                    <b-form-select-option value="1000"
                      >1000s</b-form-select-option
                    >
                    <b-form-select-option value="normal"
                      >Normal</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
          <div class="chat-room" v-if="adminAccess">
            <div class="chat-info">
              <img
                src="@/assets/images/admin.jpg"
                class="rounded-circle icon"
              />
              <div class="description">
                <div class="title">Jump into Script</div>
                <div class="description">
                  <b-form-select size="sm" v-model="AdminTweak.manualChapterID">
                    <b-form-select-option
                      v-for="(item, index) in script"
                      v-bind:key="index"
                      :value="index"
                      >{{ item.title }}</b-form-select-option
                    >
                  </b-form-select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="chat-canvas col-12 col-md-8 col-xl-10"
          v-bind:class="{ active: currentActiveChatRoom != 'pick-room' }"
          id="chatCanvas"
        >
          <!-- fact check -->
          <div
            class="col-12 chat-notification fact-check"
            v-if="gameStatus.showFactCheck"
          >
            <button
              class="button"
              @click.prevent="viewMedia(gameStatus.factCheck.image)"
              :title="userSettings['check-web']"
            >
              <img
                :alt="userSettings['check-web']"
                class="check-icon"
                src="@/assets/images/icon-check.png"
              />{{ userSettings["check-web"] }}
            </button>
          </div>
          <!-- Notification -->
          <a
            href="#"
            class="col-12 chat-notification"
            v-if="gameStatus.showNotification"
            @click.prevent="checkNotification"
          >
            <div>
              <div class="title">
                <img
                  src="@/assets/images/check-icon.svg"
                  :alt="userSettings['check-now']"
                />
                {{ userSettings["new-message-from"] }}
                {{ roomChatInfo[chatRoom].title }}
              </div>
              <div class="d-none">{{ userSettings["check-now"] }}</div>
            </div>
          </a>

          <div class="top-area">
            <div class="chat-info">
              <div
                class="d-md-none back-icon"
                @click.prevent="currentActiveChatRoom = 'pick-room'"
              >
                <img src="@/assets/images/arrow-left.svg" width="24" />
              </div>
              <template v-if="currentActiveChatRoom != 'pick-room'">
                <img
                  v-if="isIconLoaded"
                  :src="getField(roomChatInfo[currentActiveChatRoom], 'icon')"
                  class="rounded-circle icon"
                  v-bind:key="(roomChatInfo[currentActiveChatRoom], 'title')"
                />
                <img v-else class="rounded-circle icon" src="@/assets/images/loading-character.gif" @load="onIconLoad">
                <div class="description">
                  <!-- <div class="title">{{ userSettings[getField(roomChatInfo[currentActiveChatRoom], 'title')] }}</div> -->
                  <div class="title">
                    {{ $data_get(roomChatInfo[currentActiveChatRoom], 'title') }}
                  </div>
                  <div class="description">
                    {{ roomChatDescription(currentActiveChatRoom) }}
                  </div>
                  <div v-if="(getField(roomChatInfo[currentActiveChatRoom],'hide_score', 0)) == 0" class="score-top">
                    <div class="score-icon-top" :key="persistantScore">
                      <div v-if="persistantScore !== 0" class="icon-animated">
                        <LottieScore :gameSkin="gameSkin"/>
                      </div>
                      <img :class="{ scored: persistantScore !== 0 }" src="@/assets/images/icon-score-top.svg" alt="" />
                    </div>
                    <div class="score-text-top">
                      <transition name="counter">
                        <p>{{ $score_format(animatedScore) }}</p>
                      </transition>
                      <span>/{{ $score_format(max_points) }}</span>
                    </div>
                  </div>
                </div>
                <div class="speed-button">
                  <a
                    href="#"
                    class="button"
                    @click.prevent="doPlayerControl"
                    v-if="showGameSpeedControl"
                  >
                    <!-- <img v-if="gameStatus.play === false" src="@/assets/images/ico-pause.svg" /> -->
                    <template>
                      <img
                        v-if="!gameStatus.play"
                        alt="play"
                        src="@/assets/images/ico-play.svg"
                      />
                      <img
                        v-if="gameStatus.play"
                        alt="pause"
                        src="@/assets/images/ico-pause.svg"
                      />
                      <!-- <img v-if="playerControl === 'fast'" src="@/assets/images/ico-fast.svg" /> -->
                    </template>
                  </a>
                  <!-- <span class="button" @click.prevent="changeSpeed">{{ gameStatus.gameSpeed }}</span> -->
                </div>
              </template>
            </div>
            <!-- Game Score -->
            <div class="game-score">
              <div class="line">
                <div class="icon" v-bind:style="gameScoreVisual">
                  <template v-if="gameStatus.lastScore < 0">
                    <div class="score-animation-wrapper wrong">
                      <div class="score">{{ gameStatus.lastScore }}</div>
                      <div class="animated-icon">
                        <Stickers stickerName="cry"></Stickers>
                      </div>
                    </div>
                  </template>
                  <template v-if="gameStatus.lastScore > 0">
                    <div class="score-animation-wrapper correct">
                      <div class="score">+{{ gameStatus.lastScore }}</div>
                      <div class="animated-icon">
                        <Stickers stickerName="happy1"></Stickers>
                      </div>
                    </div>
                  </template>
                  <img :src="gameScoreIcon" />
                </div>
              </div>
            </div>
            <!-- progress bar -->
            <div class="progress-bar-wrapper">
              <b-progress :max="maxChapters">
                <b-progress-bar
                  :value="gameStatus.chapterCount"
                  :label="progressLabel"
                ></b-progress-bar>
              </b-progress>
            </div>
          </div>
          <!-- Chat bubles -->
          <div
            class="chat-bubble-wrapper"
            ref="chat-bubble-wrapper"
            id="chat-bubble-wrapper"
            v-bind:style="{ zIndex: zIndex }"
          >
            <template v-for="(room, index) in roomChatInfo">
              <div
                v-bind:key="'something' + index"
                class="chat-messages overwrite"
                :class="[
                  room.title,
                  {
                    front: currentActiveChatRoom == index,
                  },
                ]"
                :ref="'messages_container_' + room.title"
                :id="'messages_container_' + room.title"
              >
                <ChatBubble
                  :chatHistories="chatHistories[index]"
                  :playerName="gameStatus.playerName"
                  :score="gameStatus.score"
                  :userSettings="userSettings"
                  :max_point="max_points"
                  :roomInfo="i"
                  :gameSkin="gameSkin"
                  :gameMode="gameMode"
                  :storeBadgeJson="storeBadgeJson"
                  @viewMedia="viewMedia"
                  @imageReady="imageReady"
                ></ChatBubble>
              </div>
            </template>
          </div>

          <div class="player-actions">
            <!-- loading state -->

            <!-- go to bottom  -->
            <div id="go-to-bottom" v-if="gameStatus.userScroll">
              <!-- <div id="go-to-bottom" v-if="true"> -->
              <button class="button" @click.prevent="scrollToBottom">
                <img src="@/assets/images/icon-back.png" />
              </button>
            </div>
            <!-- next button -->
            <div class="next-button" v-if="!playerAction.show">
              <button class="btn" @click="skipMessage">
                {{ userSettings["continue"] }}
                <img
                  src="@/assets/images/arrow-right.svg"
                  :alt="userSettings['continue']"
                />
              </button>
            </div>
            <!-- player answer -->
            <div class="player-options" v-if="playerAction.show">
              <div class="label">{{ userSettings["reply"] }}</div>
              <div class="option-button-wrapper">
                <div
                  class="option"
                  v-for="option in playerAction.options"
                  :key="option.id"
                >
                  <button
                    :value="option.id"
                    class="button"
                    :title="formatMessage(option.answer)"
                    v-on:click="playerAnswer(option)"
                    v-html="formatMessage(option.answer)"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <div
            class="interstital-modal-wrapper"
            v-if="gameStatus.showInterstitalModal"
            :class="getField(current_chat_object, 'eventOption.value', 'false')"
          >
            <div class="interstital-modal">
              <div class="head">
                <template
                  v-if="current_chat_object.eventOption.value == 'correct'"
                >
                  <LottieCorrect />
                  <template
                    v-if="
                      $data_get(current_chat_object, 'eventOption.badge', false)
                    "
                  >
                    <div class="img-wrapper">
                      <BadgeAnimation v-if="hasAnimation" :animatedBadge="animationBadge" />
                      <img
                        :src="
                          $store.getters['badges/getFromSlug'](
                            $data_get(current_chat_object, 'eventOption.badge')
                          ).url
                        "
                        class="d-block"
                      />
                    </div>
                    <div class="achivement-wrapper">
                      <!-- <h5>Achievement unlocked:</h5> -->
                      <h5>{{ userSettings["achievement-unlocked"] }}</h5>
                      <h2>
                        {{
                          $store.getters["badges/getFromSlug"](
                            $data_get(current_chat_object, "eventOption.badge")
                          ).name
                        }}
                      </h2>
                    </div>
                  </template>
                  <template v-else>
                    <div class="img-wrapper">
                      <img
                        src="@/assets/images/mark-good.png"
                        :title="userSettings['you-are-right']"
                        :alt="userSettings['you-are-right']"
                        class="d-block"
                      />
                    </div>
                    <div class="achivement-wrapper">
                      <h5>{{ userSettings["achievement-unlocked"] }}</h5>
                      <h2>{{ userSettings["you-are-right"] }}</h2>
                    </div>
                  </template>
                </template>
                <template v-else>
                  <LottieWrong />
                  <div class="img-wrapper">
                    <BadgeAnimation v-if="hasAnimation" :animatedBadge="animationBadge" />
                    <div v-else>
                      <BadgeWrong v-if="gameSkin !== 'sri'"  />
                    </div>
                    <img v-if="
                      $data_get(current_chat_object, 'eventOption.badge', false)
                    "
                        :src="
                          $store.getters['badges/getFromSlug'](
                            $data_get(current_chat_object, 'eventOption.badge')
                          ).url
                        "
                        :title="userSettings['you-are-wrong']"
                        :alt="userSettings['you-are-wrong']"
                        class="d-block"
                      />
                      <img v-else
                      src="@/assets/images/wrong-interstital-3.png"
                      :title="userSettings['you-are-wrong']"
                      :alt="userSettings['you-are-wrong']"
                      class="d-block"
                    />
                  </div>
                  <div class="achivement-wrapper">
                    <h5>{{ userSettings["header-wrong-interstital"] }}</h5>
                    <h2>{{ userSettings["you-are-wrong"] }}</h2>
                  </div>
                </template>
              </div>
              <div class="content" id="interstital-content">
                <div v-html="formatMessage(current_chat_object.message)"></div>
              </div>
              <div class="action">
                <div class="container">
                  <div class="col-sm-12 col-md-6 offset-md-3">
                    <button
                      class="button mt-3 mb-2"
                      @click.prevent="closeInterstital"
                      :title="userSettings['continue']"
                    >
                      {{ userSettings["continue"] }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="end-card-result-wrapper"
            v-if="gameStatus.showEndResultCard"
          >
            <EndResultCard :userSettings="userSettings" :queryParams="queryParams"></EndResultCard>
          </div>
          <div class="choose-scenario" v-if="playerAction.showScenario">
            <div class="top-info">{{ userSettings["choose-scenario"] }}</div>
            <div class="body">
              <div
                class="card"
                v-for="option in playerAction.options"
                @click="playerSetScenario(option)"
                v-bind:key="option.nextNode + Math.random()"
              >
                <a href="#" @click.prevent>
                  <ImageLoad @onLoad="$emit('imageReady')">
                    <b-img
                      slot="image"
                      fluid
                      :src="option.image_mobile"
                      class="d-block d-lg-none"
                    />
                  </ImageLoad>
                  <ImageLoad @onLoad="$emit('imageReady')">
                    <b-img
                      slot="image"
                      fluid
                      :src="option.image_desktop"
                      class="d-none d-lg-block"
                    />
                  </ImageLoad>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { uuid } from 'vue-uuid'
import WelcomeScreen2 from '@/components/WelcomeScreen2'
import CharacterSelection from '@/components/CharacterSelection'
import ImageLoad from '@/components/ImageLoad.vue'
// import _ from 'lodash'
import buttonSound from '@/assets/sounds/button.wav'
import notificationSound from '@/assets/sounds/notification.wav'
import chatSound from '@/assets/sounds/chat.wav'
import rightSound from '@/assets/sounds/right.wav'
import Stickers from '@/components/Stickers'
import wrongSound from '@/assets/sounds/wrong.wav'
// import VueLoadImage from 'vue-load-image'
import ChatBubble from '@/components/ChatBubble.vue'
import Base from '@/mixins/base.js'
import PreviewMedia from '@/components/PreviewMedia'
import EndResultCard from '@/components/EndResultCard.vue'
import LottieCorrect from '@/components/LottieCorrect.vue'
import LottieWrong from '@/components/LottieWrong.vue'
import LottieScore from '@/components/LottieScore.vue'
import Loading from '@/components/Loading.vue'
import LoadingMsri from '@/components/Loading-msri.vue'
import LoadingFast from '@/components/LoadingFast.vue'
import BadgeAnimation from '@/components/BadgeAnimation.vue'
import BadgeWrong from '@/components/BadgeWrong.vue'

export default {
  name: 'GameTest',
  components: {
    WelcomeScreen2,
    Stickers,
    // VueLoadImage,
    PreviewMedia,
    ChatBubble,
    CharacterSelection,
    ImageLoad,
    EndResultCard,
    LottieCorrect,
    LottieScore,
    LottieWrong,
    Loading,
    LoadingMsri,
    LoadingFast,
    BadgeAnimation,
    BadgeWrong
  },
  metaInfo () {
    return {
      title: this.pageTitle
    }
  },
  mixins: [Base],
  watch: {
    persistantScore () {
      this.updateAnimatedScore()
    },
    gameMode (val) {
      if (val === 'fast') {
        this.gameStatus.welcomeScreen = false
      }
    },
    configLoaded: async function (val) {
      if (val) {
        this.initialLoad()
      }
    },
    'AdminTweak.manualChapterID': function () {
      this.nodeID = this.AdminTweak.manualChapterID
      this.setScript()
      // this.chatHistories.group = []
      // this.chatHistories.ayah = []
      // this.chatHistories.mentor = []
      // this.chatHistories.ibu = []
      // this.chatHistories.tante = []
      // this.chatHistories.om = []
      // this.chatHistories.adik = []
      // this.chatHistories.abang = []
      this.initchatHistories()
      this.playerAction.show = false
      this.playerAction.showScenario = false
      this.gameStatus.lastChatUser = 'x'
      this.gameStatus.showFactCheck = false
      this.gameStatus.viewMedia = false
      this.runGame()
    },

    rooms: function () {
      this.initchatHistories()
    }
  },
  data () {
    return {
      queryParams: {},
      checkPoint: false,
      showAnimation: false,
      storeJson: {},
      storeBadgeJson: {},
      avatars: [],
      badges: [],
      startGame: false,
      animationBadge: { animationData: null, autoplay: true, loop: false },
      preLoad: true,
      animatedScore: null,
      isIconLoaded: false,
      zIndex: 100,
      chatMessages: null, // main streamlined chat bubbles from backend
      chatHistories: {},
      script: [],
      i: 0,
      nodeID: null,
      AdminTweak: {
        gameSpeed: 'normal',
        manualChapterID: 0
      },
      playerControl: 'play',
      gameStatus: {
        play: false,
        showNotification: false,
        score: 0,
        viewMedia: false,
        playerAnswers: [],
        playerSSID: uuid.v1(),
        playerName: '',
        welcomeScreen: true,
        lastChatUser: 'x',
        notificationChatRoom: '',
        // notifchatUser: '', --refer ke chatUser()
        showNewMessage: false,
        chapterCount: 0,
        showFactCheck: false,
        showInterstitalModal: false,
        userScroll: false,
        welcomeReady: false,
        characterSelection: false,
        gameSpeed: '1x',
        showEndResultCard: false,
        gameSpeedOptions: ['.5x', '1x', '2x'],
        statepause: false
      },
      playerAction: {
        decisionID: null,
        options: [],
        show: false,
        scenario: null,
        showScenario: false
      },
      answerHistory: [],
      currentActiveChatRoom: 'mentor',
      previewMedia: {
        thumb: '',
        full: '',
        tablet: '',
        title: ''
      },
      questionCount: 0,
      roundCount: 1,
      timerCount: 0,
      soundHandler: {
        right: new Audio(rightSound),
        wrong: new Audio(wrongSound),
        button: new Audio(buttonSound),
        notification: new Audio(notificationSound),
        chat: new Audio(chatSound)
      },
      // roomChatInfo: {
      //   group: {
      //     title: 'group',
      //     icon: require('./../assets/images/profile-picture/chat-bot.png'),
      //     always_show: true
      //   },
      //   mentor: {
      //     title: 'mentor',
      //     icon: require('./../assets/images/profile-picture/chat-eka.png'),
      //     always_show: true
      //   },
      //   ayah: {
      //     title: 'ayah',
      //     icon: require('./../assets/images/profile-picture/chat-bot.png')
      //   },
      //   ibu: {
      //     title: 'ibu',
      //     icon: require('./../assets/images/profile-picture/chat-ibu.png')
      //   },
      //   om: {
      //     title: 'om',
      //     icon: require('./../assets/images/profile-picture/chat-uncle.png')
      //   },
      //   tante: {
      //     title: 'tante',
      //     icon: require('./../assets/images/profile-picture/ico-eka.png')
      //   },
      //   adik: {
      //     title: 'adik',
      //     icon: require('./../assets/images/profile-picture/chat-adik.jpg')
      //   },
      //   abang: {
      //     title: 'abang',
      //     icon: require('./../assets/images/profile-picture/chat-sibling.png')
      //   },
      //   tutorial: {
      //     title: 'tutorial',
      //     icon: require('./../assets/images/profile-picture/chat-sibling.png'),
      //     always_show: true
      //   }
      // },
      timer: 0,
      timerFunc: null,
      elapsedTime: 0,
      adminAccess: false
    }
  },
  created () {
    if (this.gameMode === 'fast') {
      this.gameStatus.welcomeScreen = false
      this.gameStatus.play = true
    }
    const urlParams = new URLSearchParams(window.location.search)

    for (const [key, value] of urlParams.entries()) {
      this.queryParams[key] = value
    }
  },

  async mounted () {
    this.updateAnimatedScore()

    setTimeout(() => {
      if (this.configLoaded) {
        this.startGame = true
        this.initialLoad()
      }
    }, 500)

    this.$bus.$on('track-event', (event, data) => {
      this.fireTrackGtm(false, event, data)
    })

    this.$bus.$on('interstitial-open', () => {
      this.zIndex = 102
      this.playerControl = 'pause'
      this.gameStatus.play = false
    })

    this.$bus.$on('interstitial-close', () => {
      this.zIndex = 100
      this.playerControl = 'play'
      this.gameStatus.gameSpeed = '1x'
      this.gameStatus.play = true
      this.runGame()
    })

    const that = this

    /**
     * restart scenario function
     */
    this.$bus.$on('restart-to-scenario', function () {
      that.roundCount = that.roundCount + 1
      that.timerCount = 0
      that.gameStatus.showEndResultCard = false
      that.gameStatus.playerAnswers = []
      that.fireTrackGtm(false, 'main-lagi')
      that.fireTrackGtm(false, 'play_again')

      // find node ID that has scenario and set it!
      let nodeID = null
      Object.keys(that.script).forEach((key) => {
        const result = that.script[key].chat_messages.find((chat) => {
          if (chat.type === 'scenario') {
            return chat
          }
        })
        if (result) {
          nodeID = key
        }
      })
      let homeNode = null
      for (var index in this.script) {
        if (this.script[index].is_home === '1') {
          homeNode = this.script[index].id
        }
      }
      that.AdminTweak.manualChapterID = homeNode
      if (nodeID) {
        setTimeout(() => {
          that.AdminTweak.manualChapterID = nodeID
        }, 150)
      }

      // also clear badges
      that.$store.dispatch('gamePlay/resetPlayerStat')

      // and clear score
      setTimeout(() => {
        that.preloadChat()
      }, 700)
      that.gameStatus.chapterCount = 0
      that.gameStatus.score = 0
    })
  },
  beforeDestroy () {
    this.$bus.$off('restart-to-scenario')
    this.$bus.$off('track-event')
    this.$bus.$off('interstitial-close')
    this.$bus.$off('interstitial-open')
  },
  computed: {
    hasAnimation () {
      const badge = this.$data_get(this.current_chat_object, 'eventOption.badge', false)
      if (badge) {
        const urlAnimationBadge = this.$store.getters['badges/getFromSlug'](badge).json
        return this.hasAnimationBadge(urlAnimationBadge)
      } else {
        return false
      }
    },
    configLoaded () {
      return this.$store.getters['gameSetting/configLoaded']
    },
    gameSkin () {
      return this.$store.getters['gameSetting/getSkin']
    },
    gameMode () {
      return this.$store.getters['gameSetting/getMode']
    },
    endMode () {
      return this.$store.getters['gameSetting/getEndMode']
    },
    max_points () {
      return this.$store.getters['gameSetting/getMaxPoint']
    },

    max_progress () {
      return this.$store.getters['gameSetting/getMaxProgress']
    },

    roomChatInfo: function () {
      return this.$store.getters['rooms/getAsGameTestFormat']
    },

    debugMode: function () {
      if (this.adminAccess) {
        // return true
      }
      const uri = window.location.search.substring(1)
      const params = new URLSearchParams(uri)
      const debugMode = params.get('debug_mode')
      if (debugMode) {
        return true
      }
      return false
    },
    showGameSpeedControl: function () {
      if (
        this.gameStatus.showNotification ||
        this.gameStatus.showInterstitalModal ||
        this.playerAction.show
      ) {
        return false
      }
      return true
    },
    // page title
    pageTitle: function () {
      return this.userSettings['page-title'] || 'Gali Fakta'
    },

    rooms: function () {
      return this.$store.getters['rooms/getRooms']
    },

    // a computed getter
    chatClassName: function () {
      return this.message.split('').reverse().join('')
    },
    chatRoom: function () {
      return this.gameStatus.notificationChatRoom
    },

    // !!!! pakai ini kalau nanti nama notif jadi pakai user !!!
    // chatUser: function () {
    //   return this.gameStatus.notifchatUser
    // },

    gameScoreVisual: function () {
      let score = (this.gameStatus.score / this.max_points) * 50
      if (score > 100) {
        score = 100
      } else if (score < 0) {
        score = 0
      }
      return {
        marginLeft: score + '%'
      }
    },
    gameScoreIcon: function () {
      var images = require.context('../assets/images/', false, /\.png$/)
      const score = this.gameStatus.score / 2
      if (score >= 0 && score <= 10) {
        return images('./neg-100.png')
      } else if (score > 10 && score <= 25) {
        return images('./neg-50.png')
      } else if (score > 25 && score <= 50) {
        return images('./0.png')
      } else if (score > 50 && score <= 75) {
        return images('./50.png')
      } else if (score > 75) {
        return images('./100.png')
      }
      return images('./0.png')
    },
    persistantScore () {
      const score = Number(this.gameStatus.score)
      const maxPoints = Number(this.max_points)
      return (score / maxPoints) * 1000
    },
    maxChapters: function () {
      // var count = 0
      // Object.keys(this.script).forEach(function (key, value) {
      //   count += parseInt(this.script[key].progress_count)
      // }.bind(this))

      return this.max_progress
      // return count
    },
    progressLabel: function () {
      let score = (this.gameStatus.chapterCount / this.maxChapters) * 100
      if (score > 100) {
        score = 100
      }
      return score.toFixed(0) + '%'
    },
    formattedElapsedTime: function () {
      const date = new Date(null)
      date.setSeconds(this.elapsedTime / 1000)
      const utc = date.toUTCString()
      return utc.substr(utc.indexOf(':') - 2, 8)
    },

    formattedTimer: function () {
      const date = new Date(null)
      date.setSeconds(this.timerCount / 1000)
      const utc = date.toUTCString()
      return utc.substr(utc.indexOf(':') - 2, 8)
    },

    current_chat_object () {
      return this.chatMessages[this.i]
    }
  },
  methods: {
    hasAnimationBadge (urlAnimationBadge) {
      for (var index in this.storeBadgeJson) {
        if (this.storeBadgeJson[index].json === urlAnimationBadge) {
          this.animationBadge = {
            ...this.animationBadge,
            animationData: this.storeBadgeJson[index].file
          }
          return true
        }
      }
      return false
    },
    getBadgeFilename () {
      const url = this.$store.getters['badges/getFromSlug'](this.$data_get(this.current_chat_object, 'eventOption.badge')).url
      const parts = url.split('/')
      const filename = parts[parts.length - 1]
      return filename
    },
    updateAnimatedScore () {
      const targetScore = Number(this.gameStatus.score)
      const duration = 250 // Adjust the duration as needed (in milliseconds)
      const steps = 100 // Adjust the number of steps as needed

      const stepValue = (targetScore - this.animatedScore) / steps
      let stepCount = 0

      if (targetScore !== 0) {
        const updateStep = () => {
          this.animatedScore += stepValue
          this.animatedScore = Math.round(this.animatedScore)
          stepCount++

          if (stepCount < steps) {
            setTimeout(updateStep, duration / steps)
          } else {
            this.animatedScore = targetScore // Set the animated score to the exact target score after the animation completes
          }
        }

        setTimeout(updateStep, duration / steps)
      } else {
        this.animatedScore = 0
      }
    },
    onIconLoad () {
      this.isIconLoaded = true
    },
    onIconStandby () {
      this.isIconLoaded = false
    },
    async initchatHistories () {
      const rooms = this.$store.getters['rooms/getRooms']
      rooms.forEach((room) => {
        this.chatHistories[room.slug] = []
      })
    },
    getUUID () {
      return uuid.v1()
    },
    // room chat description
    roomChatDescription (roomChat = null) {
      // if eka is her turn, turn back online
      // family just leave it as it is
      if (roomChat === 'mentor' || roomChat === 'eka') {
        if (
          this.gameStatus.lastChatUser === 'Eka' ||
          this.gameStatus.lastChatUser === 'user'
        ) {
          return this.userSettings.online
        } else {
          return this.userSettings.offline
        }
      }
      return ''
      // return this.userSettings[this.roomChatInfo[roomChat].title + '-sub' || '']
    },

    /**
     * method to update player control between
     * play / pause / fast fwd
     */
    doPlayerControl () {
      /**
       * only process IF user is not scrolling up
       */
      if (!this.gameStatus.userScroll) {
        // if (this.playerControl === 'play') {
        //   this.playerControl = 'fast'
        //   this.gameStatus.gameSpeed = '2x'
        // }
        if (this.playerControl === 'play') {
          this.playerControl = 'pause'
          this.gameStatus.play = false
          this.gameStatus.statepause = true
          console.log(this.gameStatus.statepause)
        } else {
          this.playerControl = 'play'
          this.gameStatus.gameSpeed = '1x'
          this.gameStatus.play = true
          this.gameStatus.statepause = false
          console.log(this.gameStatus.statepause)
          this.runGame()
        }
      }
    },

    preloadChat () {
      this.fireTrackGtm(false, 'game_started')
      const filteredObj = Object.keys(this.script)
        .filter((key) => this.script[key].preload_script)
        .reduce((result, key) => {
          result[key] = this.script[key]
          return result
        }, {})

      Object.keys(filteredObj).forEach((value, index) => {
        if (filteredObj[value].chat_messages) {
          filteredObj[value].chat_messages.forEach((chat) => {
            /**
             * only process chat! no decision, redirect or event
             */
            if (chat.type === 'chat') {
              const chatRoom = chat.chatRoom.toString()
              chat.lastChatUser = 'x'
              chat.historyID = uuid.v1()
              this.chatHistories[chatRoom].push(chat)
            }
          })
        }
      })
    },

    fireTrackGtm (gameCompleted = false, eventType = null, extraData = null) {
      if (this.GTM_TRACK) {
        this.$gtm.enable(this.GTM_TRACK)
        try {
          const gameData = {
            lastQuestion: this.questionCount,
            totalScore: this.gameStatus.score,
            gameCompleted: gameCompleted,
            timeStamp: this.formattedElapsedTime,
            gameRound: this.roundCount,
            totalTimeStamp: this.formattedElapsedTime,
            currentTimeStamp: this.formattedTimer,
            playerName: ''
          }

          this.$gtm.trackEvent({
            event: 'game', // Event type [default = 'interaction'] (Optional)
            event_type: eventType,
            gameData: gameData,
            scores: this.gameStatus.playerAnswers,
            ...extraData
          })
        } catch (err) {
          if (this.debugMode) {
            console.log(err, 'gtm')
          }
        }
      }
    },

    async initialLoad () {
      await this.getBaseUrl().then(async () => {
        await this.makeCall().then((response) => {
          this.script = response.data
          this.gameStatus.welcomeReady = true
          this.mainChatHistories = this.chatHistories.group
          for (var index in this.script) {
            if (this.script[index].is_home === '1') {
              this.nodeID = this.script[index].id
            }
          }
          if (this.gameMode === 'fast') {
            setTimeout(() => {
              this.preLoad = false
              this.AdminTweak.manualChapterID = this.nodeID
            }, 700)
            setTimeout(() => {
              this.preloadChat()
            }, 700)
          } else {
            this.AdminTweak.manualChapterID = this.nodeID
          }
          this.setScript()
          this.initchatHistories()

          /**
           * determine what the first chat group is
           */
          if (this.script[this.nodeID]) {
            this.currentActiveChatRoom = this.script[this.nodeID].chat_messages[0]
              ? this.script[this.nodeID].chat_messages[0].chatRoom
              : 'group'
          }
        })
        this.fireTrackGtm()
        // for analytic
        this.timer = setInterval(() => {
          this.elapsedTime += 1000
          this.timerCount += 1000
        }, 1000)
        this.checkAdminAccess()

        // this.getMaxPoint()
        this.$store.commit('gamePlay/setSessionId', this.gameStatus.playerSSID)

        this.loadCharAnimation()
        this.loadBadgeAnimation()
      })
    },
    async loadCharAnimation () {
      this.avatars = this.$store.getters['avatars/getAvatars']
      const length = this.avatars.length
      for (var index in this.avatars) {
        try {
          if (this.avatars[index].json) {
            const response = await this.$http.get(this.avatars[index].json)
            this.storeJson[index] = {
              json: this.avatars[index].json,
              file: response.data
            }
          }
        } catch (err) {
          console.log(err)
        }
        index++
      }
      if (index === length) {
        this.showAnimation = true
      }
    },
    async loadBadgeAnimation () {
      this.badges = this.$store.getters['badges/getBadges']
      for (var index in this.badges) {
        try {
          if (this.badges[index].json) {
            const response = await this.$http.get(this.badges[index].json)
            this.storeBadgeJson[index] = {
              json: this.badges[index].json,
              file: response.data
            }
          }
        } catch (err) {
          console.log(err)
        }
        index++
      }
    },
    changeSpeed () {
      this.soundHandler.button.play()
      if (this.gameStatus.gameSpeed === '1x') {
        this.gameStatus.gameSpeed = '2x'
      } else if (this.gameStatus.gameSpeed === '2x') {
        this.gameStatus.gameSpeed = '.5x'
      } else {
        this.gameStatus.gameSpeed = '1x'
      }
    },
    switchToEditor () {
      this.gameStatus.play = false
      this.$router.push('/cms')
    },
    changeRoom (index) {
      // in case player open a chat room that notification came from,
      // lets not show the notification
      if (
        this.gameStatus.notificationChatRoom === index &&
        this.gameStatus.showNotification
      ) {
        this.checkNotification()
        // return
      }
      this.currentActiveChatRoom = index
      var content = this.$refs['chat-bubble-wrapper']
      content.scrollTop += content.scrollHeight + 500
      this.scrollCanvas()
      this.resetNewMessage()
    },
    checkAdminAccess () {
      const args = {
        action: 'get_all_nodes',
        'game-slug': this.getGameSlug()
      }
      this.$http
        .post(this.baseURL + '/moonshot-game', args)
        .then((respnse) => {
          this.adminAccess = true
        })
        .catch(() => {
          this.adminAccess = false
        })
    },
    /**
     * Preview Media
     */
    viewMedia (image) {
      this.fireTrackGtm(false, 'screenshot_expansion', {
        image: image
      })
      if (image.mobile.length === 0 && image.tablet.length === 0) {
        console.log('nope, just thumbnail')
        return
      }
      if (!this.gameStatus.showNotification) {
        this.previewMedia = image
        this.previewMedia.id = uuid.v1()
        this.gameStatus.viewMedia = true
        this.gameStatus.play = false
        this.soundHandler.button.play()
      }
    },
    closeViewMedia () {
      this.gameStatus.viewMedia = false
      /**
       * do not continue the game
       * if user currently scrolled up
       */
      if (this.gameStatus.userScroll === false) {
        this.gameStatus.play = true
        this.runGame()
      }
      this.soundHandler.button.play()
    },
    scrollToBottom () {
      this.gameStatus.userScroll = false
      this.scrollCanvas()
      this.gameStatus.play = true
      this.runGame()
    },
    /**
     * scroll canvas when image is fully rendered
     */
    imageReady () {
      setTimeout(() => {
        this.scrollCanvas()
      }, 1500)
    },
    /**
     * Scroll chat canvas
     */
    scrollCanvas () {
      /**
       * Auto scroll chat window
       */
      if (this.debugMode) {
        console.log(
          {
            gameStatus: this.gameStatus,
            gameStatusPlay: this.gameStatus.play,
            chatHistories: this.chatHistories,
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on scrollCanvas'
        )
      }
      const $this = this
      setTimeout(() => {
        // this.$refs['chat-bubble-wrapper'].addEventListener('scroll', function () {
        // Object.entries($this.roomChatInfo).forEach((val, index) => {
        const wrapper = '#chat-bubble-wrapper'
        var content = $this.$el.querySelector(wrapper)
        if (
          typeof content !== 'undefined' &&
          $this.gameStatus.userScroll === false
        ) {
          if (!$this.gameStatus.userScroll) {
            content.scrollTop += content.scrollHeight + 500
            // console.log(content.scrollTop, 'scrolltop')
            // console.log(content.scrollHeight, 'scrollHeight')
            // console.log('scrolling' + wrapper)
          }
        }
        // })
      }, 100)
    },
    /**
     * method to show messages to chat bubles area
     */
    showMessage (chatObj) {
      // console.log(chatObj, 'here?')
      const chat = JSON.parse(JSON.stringify(chatObj))

      chat.historyID = uuid.v1()

      chat.lastChatUser = this.gameStatus.lastChatUser

      const chatRoom = chat.chatRoom.toString()
      // const chatUser = chat.user.toString() --refer ke chatUser()
      if (typeof this.chatHistories[chatRoom] === 'undefined') {
        return
      }

      /**
       * trick to play animation ONLY on new chat
       */
      chat.playAnimation = true

      setTimeout(() => {
        chat.playAnimation = false
      }, 1000)

      if (this.currentActiveChatRoom !== chatRoom) {
        this.gameStatus.notificationChatRoom = chatRoom

        // this.gameStatus.notifchatUser = chatUser   --refer ke chatUser()
        this.showNotification()
        this.mainChatHistories = this.chatHistories[chatRoom]
        if (this.chatHistories[chatRoom].length > 0) {
          chat.isNewMessage = true
        }
      } else if (this.gameStatus.showNewMessage) {
        this.gameStatus.showNewMessage = false
        chat.isNewMessage = true
      }

      this.chatHistories[chatRoom].push(chat)

      this.gameStatus.lastChatUser = chat.user
      // this.gameStatus.i = chatRoom
      if (chatObj.type !== 'answer') {
        this.soundHandler.chat.play()
      }

      if (this.debugMode) {
        console.log(
          {
            chatObj: chatObj,
            gameStatus: this.gameStatus,
            mainChatHistories: this.mainChatHistories,
            chatHistories: this.chatHistories,
            chatHistoriesChatRoom: this.chatHistories[chatRoom],
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on showMessage'
        )
      }

      this.scrollCanvas()
    },

    /**
     * reset new message flag
     */
    resetNewMessage () {
      Object.entries(this.roomChatInfo).forEach((val, index) => {
        const slug = val[0]
        for (let i = 0; i < this.chatHistories[slug].length; i++) {
          this.chatHistories[slug][i].isNewMessage = false
        }
      })
    },
    /**
     * notification module
     */
    showNotification () {
      if (this.debugMode) {
        console.log(
          {
            gameStatus: this.gameStatus,
            gameStatusPlay: this.gameStatus.play,
            mainChatHistories: this.mainChatHistories,
            chatHistories: this.chatHistories,
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on showNotification'
        )
      }
      this.gameStatus.showNotification = true
      this.gameStatus.play = false
      this.soundHandler.notification.play()
      // console.log('showNotification')
      // console.log(this.gameStatus.play)
    },
    checkNotification () {
      /**
       * switch active chat room
       */
      const lastChatroom = this.chatMessages[this.i - 1].chatRoom
      this.currentActiveChatRoom = lastChatroom
      this.gameStatus.showNotification = false
      this.soundHandler.button.play()
      this.scrollCanvas()
      if (this.gameStatus.play === false) {
        this.gameStatus.play = true
        this.runGame()
      }
    },
    /**
     * end
     */
    runGame () {
      var timer = 400
      if (this.chatMessages) {
        if (this.i < this.chatMessages.length - 1) {
          timer = this.chatMessages[this.i].timer
        }
      }

      /**
       * new feature 25 April 2020
       */
      if (this.gameStatus.gameSpeed !== '1x') {
        if (this.gameStatus.gameSpeed === '2x') {
          timer = timer / 2
        } else {
          timer = timer * 2
        }
      }

      if (this.debugMode) {
        console.log(
          {
            timer: timer,
            gameStatus: this.gameStatus,
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on runGame'
        )
      }
      /**
       * if first chat, lets skip the timer
       */

      if (
        this.$data_get(this.chatHistories, this.currentActiveChatRoom, [])
          .length === 0
      ) {
        this.$store.commit('SET_CHAT_LOADING', false)
        timer = 50
      }

      if (this.AdminTweak.gameSpeed !== 'normal') {
        timer = this.AdminTweak.gameSpeed
      }

      if (this.debugMode) {
        console.log(this.gameStatus.play, 'debug gamestatus.play on runGame2')
      }
      if (this.gameStatus.play === false) {
        this.$store.commit('SET_CHAT_LOADING', false)
        return
      }

      /**
       * show player bubble (is typing ...)
       */
      this.$store.commit('SET_CHAT_LOADING', false)
      if (timer > 2000) {
        setTimeout(() => {
          this.$store.commit('SET_CHAT_LOADING', true)
          this.scrollCanvas()
        }, 600)
      }

      this.timerFunc = setTimeout(() => {
        this.processGame()
      }, timer)
    },

    processGame () {
      /**
       * Determine if current node is ending (end game?)
       */
      if (this.debugMode) {
        console.log(
          {
            i: this.i,
            chatMessages: this.chatMessages,
            chatMessagesI: this.chatMessages[this.i],
            gameStatus: this.gameStatus
          },
          'debug on processGame'
        )
      }
      if (this.chatMessages.length - 1 === this.i) {
        /**
         * last Item! check if this is a decision, else we show ending
         */
        if (this.chatMessages[this.i].type.toString() === 'decision') {
          /**
           * show option box!
           */
          this.askPlayer()
        } else if (this.chatMessages[this.i].type.toString() === 'scenario') {
          /**
           * show Scenario
           */
          this.showScenario()
        } else if (this.chatMessages[this.i].type.toString() === 'event') {
          this.processEvent(this.chatMessages[this.i])
        } else {
          /**
           * Ending!
           */
          this.showMessage(this.chatMessages[this.i])
          this.showEnding()
          // console.log('ending1')
        }
      } else if (this.chatMessages.length - 1 <= this.i) {
        // ending as well!

        if (this.debugMode) {
          console.log(
            {
              gameStatus: this.gameStatus,
              gameStatusPlay: this.gameStatus.play,
              mainChatHistories: this.mainChatHistories,
              chatHistories: this.chatHistories,
              currentActiveChatRoom: this.currentActiveChatRoom
            },
            'debug on processGame route showEnding'
          )
        }
        this.gameStatus.play = false
        // console.log('ending2')
        this.showEnding()
      } else {
        if (this.chatMessages[this.i].type.toString() === 'decision') {
          /**
           * show option box!
           */
          this.askPlayer()
        } else if (this.chatMessages[this.i].type.toString() === 'scenario') {
          /**
           * show decisions
           */
          this.showScenario()
        } else if (this.chatMessages[this.i].type.toString() === 'event') {
          /**
           * check what type of an event is this?
           */
          this.processEvent(this.chatMessages[this.i])
        } else {
          if (this.debugMode) {
            console.log(
              this.gameStatus.play,
              'debug gamestatus.play on processGame route showMessage'
            )
          }
          if (this.gameStatus.play) {
            /**
             * GameRule!
             * 1. show chat in order of where it should appear
             */
            this.showMessage(this.chatMessages[this.i])
            this.i = this.i + 1

            /**
             * Auto Scroll if user is focus at the bottom
             */
            var container = this.$el.querySelector('#chatCanvas')
            container.scrollTop = container.scrollHeight
            this.runGame()
            this.scrollCanvas()
          }
        }
      }
    },

    processEvent (event) {
      if (this.debugMode) {
        console.log(
          {
            event: event,
            gameStatus: this.gameStatus,
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on processEvent'
        )
      }
      if (event.eventOption.type === 'redirect') {
        this.nodeID = event.eventOption.value
        this.gameStatus.play = true
        this.setScript()
        this.runGame()
      } else if (event.eventOption.type === 'end-game') {
        // this.gameStatus.score
        const score = this.gameStatus.score
        let ending = 'E'

        if (score >= 8) {
          ending = 'A'
        } else if (score >= 5 && score <= 7) {
          ending = 'B'
        } else if (score >= 4) {
          ending = 'C'
        } else if (score >= 0 && score <= 3) {
          ending = 'D'
        }
        let lastChapterNode = false
        Object.keys(this.script).forEach(
          function (key, value) {
            if (this.script[key].end_score === ending) {
              lastChapterNode = key
            }
          }.bind(this)
        )

        if (lastChapterNode) {
          this.nodeID = lastChapterNode
          this.setScript()
          this.runGame()
        }
      } else if (event.eventOption.type === 'score') {
        event.finalScore = (this.gameStatus.score / this.max_points) * 100
        // this.showMessage(event)
        /**
         * show end card result
         */

        this.gameStatus.showEndResultCard = true
        this.$store.commit('gamePlay/setScore', this.persistantScore)

        this.fireTrackGtm(true, 'game_completed')
        // this.i = this.i + 1
        // this.runGame()
      } else if (event.eventOption.type === 'progress-bar') {
        if (event.eventOption.value === 'reset') {
          this.gameStatus.chapterCount = 0
          this.gameStatus.score = 0
        } else {
          this.gameStatus.chapterCount++
        }
        this.i = this.i + 1
        this.runGame()
      } else if (event.eventOption.type === 'cek-literata') {
        this.showMessage(event)
        this.i = this.i + 1
        this.runGame()
      } else if (event.eventOption.type === 'interstital') {
        // do it here
        if (event.eventOption.value === 'correct') {
          const badge = this.$data_get(event, 'eventOption.badge', false)
          if (badge) {
            const badgeObj = this.$store.getters['badges/getFromSlug'](badge)
            this.$store.commit('gamePlay/pushBadge', badgeObj)
          }
        }
        this.gameStatus.showInterstitalModal = true
      } else if (event.eventOption.type === 'avatar-selection') {
        this.gameStatus.play = false
        this.gameStatus.characterSelection = true
      }
    },

    closeInterstital () {
      this.soundHandler.button.play()
      this.gameStatus.showInterstitalModal = false
      this.gameStatus.showNewMessage = true
      this.showMessage(this.chatMessages[this.i])
      this.i++
      this.skipMessage()
    },

    showEnding () {
      /**
       * Tracking GTM
       */
      this.fireTrackGtm(true, 'game_completed')
    },
    /**
     * this function show a pop up of option and ask user for decision
     */
    askPlayer () {
      const decision = this.chatMessages[this.i]
      const options = this.chatMessages[this.i].decisionOptions
      this.playerAction.show = true
      this.gameStatus.play = false
      this.playerAction.chatRoom = this.chatMessages[this.i].chatRoom
      this.playerAction.decisionID = this.chatMessages[this.i].id
      this.playerAction.options = options
      if (decision.image.useImage) {
        /**
         * show fact check!
         */
        this.gameStatus.showFactCheck = true
        this.gameStatus.factCheck = decision
      }
      /**
       * check if this decision in another chat Room
       */
      if (
        this.chatMessages[this.i].chatRoom.toString() !==
        this.currentActiveChatRoom
      ) {
        this.currentActiveChatRoom =
          this.chatMessages[this.i].chatRoom.toString()
      }

      if (this.debugMode) {
        console.log(
          {
            decision: decision,
            options: options,
            playerAction: this.playerAction,
            gameStatus: this.gameStatus,
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on askPlayer'
        )
      }
      this.scrollCanvas()
    },

    showScenario () {
      this.playerAction.showScenario = true
      this.gameStatus.play = false

      if (this.debugMode) {
        console.log(
          {
            gameStatus: this.gameStatus,
            gameStatusPlay: this.gameStatus.play,
            mainChatHistories: this.mainChatHistories,
            chatHistories: this.chatHistories,
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on showScenario'
        )
      }
      this.playerAction.options = this.chatMessages[this.i].scenarioOptions
    },

    playerSetScenario (answer) {
      // this.i++
      this.soundHandler.button.play()
      this.questionCount++

      this.gameStatus.play = true
      this.playerAction.showScenario = false
      this.gameStatus.userScroll = false

      if (this.debugMode) {
        console.log(
          {
            gameStatus: this.gameStatus,
            gameStatusPlay: this.gameStatus.play,
            mainChatHistories: this.mainChatHistories,
            chatHistories: this.chatHistories,
            currentActiveChatRoom: this.currentActiveChatRoom
          },
          'debug on playerSetScenario'
        )
      }
      answer.answer = null
      answer.points = 0
      if (answer.nextNode) {
        /**
         * prevent notification on selecting scenario
         */
        try {
          this.currentActiveChatRoom = this.script[answer.nextNode].chat_messages[0].chatRoom
          this.onIconStandby()
        } catch (err) {
          console.log(
            'failed to prevent notification possibly misconfigured scenario!'
          )
          console.log(err)
        }
      }

      this.fireTrackGtm(false, 'topic_selected', {
        selected: answer?.title,
        value: answer?.title,
        complete_object: answer
      })
      this.playerAnswer(answer)
    },

    skipMessage () {
      clearTimeout(this.timerFunc)
      if (this.gameStatus.play) {
        this.processGame()
      }
    },

    /**
     * let's record every answer including point the player has.
     */
    playerAnswer (answer) {
      this.soundHandler.button.play()
      this.questionCount++

      this.resetNewMessage()

      var chat = JSON.parse(JSON.stringify(answer))
      chat.historyID = uuid.v1()
      chat.type = 'answer'
      chat.chatRoom = this.chatMessages[this.i].chatRoom
      chat.message = chat.answer
      chat.user = 'user'
      chat.score = answer.points

      /**
       * put it in history
       */
      var playerAnswer = {}
      playerAnswer.question = this.questionCount
      playerAnswer.score = chat.score
      playerAnswer.answer = answer.answer
      playerAnswer.time = this.formattedElapsedTime

      this.gameStatus.playerAnswers.push(playerAnswer)
      this.gameStatus.showFactCheck = false

      setTimeout(() => {
        /**
         * save it!
         */
        const arr = {
          uuid: this.gameStatus.playerSSID,
          game_version: this.getGameSlug(),
          player_name: this.$store.getters['gamePlay/getName'],
          score: this.persistantScore,
          avatar: this.$store.getters['gamePlay/getAvatar'].url,
          answers: this.gameStatus.playerAnswers
        }
        // post it to server
        const args = {
          action: 'record_player_answer',
          player_data: arr
        }
        this.makeCall(args)
      }, 500)

      this.fireTrackGtm()
      chat.className = 'neutral'
      if (chat.score > 0) {
        // true answer
        this.fireTrackGtm(false, 'game_scores')
        this.soundHandler.right.play()
        chat.className = 'correct'
      } else if (chat.score < 0) {
        // wrong answer
        chat.className = 'wrong'
        this.soundHandler.wrong.play()
      }

      if (parseInt(chat.score, 10) > 0) {
        this.gameStatus.score += parseInt(chat.score, 10)
      }

      this.gameStatus.lastScore = parseInt(chat.score, 10)
      setTimeout(() => {
        this.gameStatus.lastScore = 0
        this.$set(this.gameStatus, 'lastScore', 0)
      }, 2000)

      // then we check if we need to switch node.
      if (chat.message) {
        this.showMessage(chat)
      }

      this.gameStatus.play = true
      this.playerAction.show = false

      if (answer.nextNode.toString() !== '-1') {
        this.nodeID = answer.nextNode
        // this.gameStatus.chapterCount++
        this.setScript()
      } else {
        // staying here!
        this.i++
      }
      this.gameStatus.userScroll = false
      this.scrollCanvas()
      this.runGame()
    },
    /**
     * this function do set the script based on the nodeID supplied
     */
    setScript () {
      if (!this.gameStatus.welcomeScreen) {
        this.gameStatus.play = true
      }
      this.i = 0
      if (typeof this.script[this.nodeID] !== 'undefined') {
        this.chatMessages = this.script[this.nodeID].chat_messages
      } else {
        // console.log(this.nodeID)
        console.log('error. script cant be loaded')
      }
    },

    /**
     * this function determine whether if we should do
     * auto scroll or not -- used mainly on event listener scroll
     */
    doAutoScroll () {
      /**
       * if player hit pause, then we should not continue the game
       */
      if (this.playerControl !== 'pause') {
        return false
      }
      /**
       * if there is a notification, then no scroll too!
       */
      if (this.gameStatus.showNotification) {
        return false
      }
      return true
    },

    backToWelcomeScreen () {
      this.gameStatus.welcomeReady = true
      this.gameStatus.characterSelection = false
    },

    /**
     * coming from welcome Screen!
     */
    setPlayerName (name) {
      if (this.gameMode === 'fast_alt') {
        this.setPlayerCharacter(name)
      } else {
        if (name === '') {
          this.gameStatus.playerName = this.userSettings['your-name-empty']
        } else {
          this.gameStatus.playerName = name
        }
        this.gameStatus.welcomeReady = false
        this.gameStatus.characterSelection = true
      }
      // this.fireTrackGtm(false, 'game_started')
      this.preloadChat()
    },

    closeSelection () {
      this.gameStatus.welcomeScreen = false
      this.gameStatus.characterSelection = false
      this.gameStatus.play = true
      this.soundHandler.button.play()

      const extraData = {
        selected: this.$store.getters['gamePlay/getAvatar']?.title,
        avatar: this.$store.getters['gamePlay/getAvatar']
      }
      this.fireTrackGtm(false, 'avatar_selected', extraData)

      /**
       * Set if chat after select avatar considered as new chat
       */
      this.showMessage(this.chatMessages[this.i])
      this.i++
      this.runGame()
    },

    setPlayerCharacter (char) {
      this.gameStatus.welcomeScreen = false
      this.gameStatus.playerCharacter = char
      this.gameStatus.characterSelection = false
      this.gameStatus.play = true
      this.soundHandler.button.play()

      setTimeout(() => {
        this.setScript()
        this.runGame()

        this.scrollCanvas()

        const $this = this

        var content = $this.$refs['chat-bubble-wrapper']
        this.$refs['chat-bubble-wrapper'].addEventListener(
          'scroll',
          function () {
            const maxScrollPosition =
              content.scrollHeight - content.clientHeight
            // console.log(content.scrollTop + ' ' + maxScrollPosition)
            if (content.scrollTop < maxScrollPosition - 200) {
              if ($this.AdminTweak.gameSpeed === 'normal') {
                $this.gameStatus.userScroll = true
                $this.gameStatus.play = false
              }
            } else {
              if (!$this.gameStatus.statepause) {
                $this.gameStatus.userScroll = false
                if ($this.gameStatus.play === false) {
                  // if ($this.currentActiveChatRoom === 'group') {
                  // console.log('BUG')
                  if (!$this.gameStatus.showNotification) {
                    $this.gameStatus.play = true
                    $this.runGame()
                  }
                  // }
                }
              }
            }
          },
          false
        )
      }, 500)
    }
  },
  destroyed () {
    this.gameStatus.play = false
  }
}
</script>
