import { render, staticRenderFns } from "./Background2.vue?vue&type=template&id=61d716c4&scoped=true"
import script from "./Background2.vue?vue&type=script&lang=js"
export * from "./Background2.vue?vue&type=script&lang=js"
import style0 from "./Background2.vue?vue&type=style&index=0&id=61d716c4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61d716c4",
  null
  
)

export default component.exports