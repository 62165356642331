<template>
    <div>
        <img :src="content" class="img-fluid" />
        <button @click.prevent="pickImage">Pick Image</button>
        <button @click.prevent="remove">Remove</button>
        <media-manager-v-2 :ref="id"></media-manager-v-2>
    </div>
</template>
<script>
import { uuid } from 'vue-uuid'
import MediaManagerV2 from '../components/MediaManagerV2.vue'

export default {
    components: {
        MediaManagerV2
    },
    props: ['value'],
    data () {
        return {
            content: this.value,
            open: false,
            id: uuid.v1()
        }
    },
    mounted () {
        this.content = this.value
    },
    watch: {
        content: function () {
            this.$emit('input', this.content)
            this.value = this.content
        }
    },
    methods: {
        async pickImage () {
            this.open = true
            const result = await this.$refs[this.id].pick()
            this.content = result.url
            this.open = false
        },

        remove () {
            this.content = null
        }
    }
}
</script>
