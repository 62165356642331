<template>
  <div class="tool col-12">
    <div @click="modal = true">
      <h6>Global Settings</h6>
    </div>

    <b-modal hide-footer size="xl" v-model="modal">
      <b-tabs content-class="mt-3" v-model="tabIndex">
        <b-tab title="Badges" active>
          <manage-badges ref="child0"></manage-badges>
        </b-tab>
        <b-tab title="Avatar">
          <manage-avatars ref="child1"></manage-avatars>
        </b-tab>
        <b-tab title="Rooms">
          <!-- <p>I'm the second tab</p> -->
          <manage-rooms ref="child2"></manage-rooms>
        </b-tab>
        <b-tab title="Actors">
          <!-- <p>I'm a disabled tab!</p> -->
          <manage-actors ref="child3"></manage-actors>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>
import ManageAvatars from './ManageAvatars.vue'
import ManageBadges from './ManageBadges.vue'
import ManageRooms from './ManageRooms.vue'
import ManageActors from './ManageActors.vue'
export default {
  components: { ManageBadges, ManageAvatars, ManageRooms, ManageActors },
  data () {
    return {
      modal: false,
      tabIndex: 0
    }
  },
  watch: {
    tabIndex: function () {
      const refname = 'child' + this.tabIndex.toString()
      this.$refs[refname].refresh()
    }
  }
}
</script>
<style lang="scss" >
</style>
