<template>
    <div>
        <div v-if="(typeof stickerName !== 'undefined')">
            <div class="sticker stickerChat">
                <Sticker
                    :animation="sum[stickerName]"
                    :loop="loop"
                    :pending="pending"
                >
                </Sticker>
            </div>
        </div>
        <div v-if="!stickerName" class="container sticker-manager">
            <div class="row">
                <div class="col-12">
                    <h3>Sticker Picker</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <template v-for="(value, key) in sum" >
                        <div class="sticker-wrapper" :class="key" @click.prevent="selectSticker(key)" v-bind:key="key">
                            <Sticker
                                :animation="value"
                                :loop="false"
                                :noPlay="true"
                            >
                            </Sticker>
                        </div>
                    </template>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Sticker from '@/components/Sticker'
// import { StickerSet } from '@/assets/emoji/list'

  export default {
    name: 'Stickers',
    props: ['stickerName', 'loop', 'pending'],
    components: {
        Sticker
    },
    data () {
        return {
            sum: this.$StickerSet
        }
    },
    created () {
        this.init()
    },
    methods: {
        init () {
        },
        selectSticker (index) {
            this.$emit('select', index)
        }
    },
    beforeDestroy: function () {
    }
}
</script>
